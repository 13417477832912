<h1 mat-dialog-title>Préférences des cookies</h1>
<mat-dialog-content class="mat-typography">


    <mat-slide-toggle [checked]="true" [disabled]="true"><span style="font-size: 1.1rem;">Cookies strictement nécessaires</span></mat-slide-toggle>
    <p>Ces cookies sont nécessaires au fonctionnement de la plateforme. Ils nous permettent de mesurer le trafic du site et d'assurer sa sécurité de façon anonyme. nous ne serons pas en mesure de vous proposer certaines fonctionnalités et nos services ne pourront pas fonctionner convenablement sans cette technologie, .</p>
    <mat-slide-toggle [(ngModel)]="data.acceptAnalytics"><span style="font-size: 1.1rem;">Cookies analytiques</span></mat-slide-toggle>
    <p>Ces cookies sont utilisés pour stocker des informations sur l'utilisation de nos services de la plateforme comme par exemple, les pages visitées, le temps passé sur la platforme, les liens cliqués, d'où vous venez...)</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Retour</button>
  <button type="submit" [mat-dialog-close]="data.acceptAnalytics" cdkFocusInitial mat-raised-button > Enregistrer </button>
</mat-dialog-actions>

export enum categoryEnum {
  electro = "electro",
  videoGame = "videoGame",
  fashion = "fashion",
  cosmetic = "cosmetic",
  sport = "sport",
  family = "family",
  home = "homeCategory",
  trip = "trip",
  mechanic = "mechanic",
  gardenDIY = "gardenDIY",
  service = "service",
  featuredBrand = "featuredBrand",
  crypto = "crypto",
  free = "free",
  deal = "deal",
  training = "training",
  grocery = "grocery",
  finance = "finance",
  animal = "animal",
}

export enum dealTypeEnum {
  goodPlan= 'bon plan',
  promotionCode= 'promotionCode',
}

export enum contactTypeEnum {
  partenariats= 'partenariats',
  autre= 'autre',
}

export enum roleEnum {
  admin= 'admin',
  editor= 'editor',
  public= "public"
}








export enum pathRoutingEnum {
  // ROLE PUBLIC
  dashboard = '/',
  promotion = '/promotion',
  source = 'source',
  promotionCode = '/promotionCode',
  about = 'about',
  partner = 'partner',
  privacyPolicy = 'privacyPolicy',
  cookies = 'cookies',
  legalNotice = 'legalNotice',
  product = 'product',
  login = 'login',
  contact = 'contact',
  free = 'free',
  menuMobile = "menuMobile",
  search = "search",
  category = "category"
}

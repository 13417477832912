import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { HeaderStickyFrontOfficeComponent} from '../../componenets/header-sticky/header-sticky-front-office/header-sticky-front-office.component';
import { FormGroup, FormBuilder, Validators, NgForm, UntypedFormControl } from '@angular/forms';
import { emailValidator } from "src/app/shared/email-validator.directive";
import { Newsletter } from "src/app/models/newsletter";
import { SaveNewsletterAction } from "src/app/ngrx/newsletter/newsletter.action";
import { Store } from "@ngrx/store";
import { DialogContentNewsLetterDisable } from "../newsletter-dialog-disable/dialog-content-newsletter-disable";

export interface DialogData {
  acceptNewsLetter: boolean;
  email: any;
}

@Component({
  selector: 'dialog-content-newsletter',
  templateUrl: 'dialog-content-newsletter.html',
})
export class DialogContentNewsLetter {

  form!: FormGroup;
  acceptNewsLetter: boolean = false;
  newsletter = {} as Newsletter;
  email!: string;
  cookiesNewsletter!: string | null;

  constructor(public DialogRef: MatDialogRef<HeaderStickyFrontOfficeComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogContentNewsLetter>,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.newsletter.email, [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator()]),
      active: true,
      addedDate: new Date(),
    });
  }

  submit() {
    this.dialogRef.close({
      acceptNewsLetter: this.acceptNewsLetter,
      email: this.form.controls['email'].value,
    });
    this.onSaveNewsletter();
  }

  onSaveNewsletter(){
    if (this.form.invalid) {
      return;
    }
    // this.form.addControl('active', new UntypedFormControl(true));
    // this.form.addControl('addedDate', new Date());

    // this.contactService.save(this.form?.value).subscribe();
    this.store.dispatch(new SaveNewsletterAction(this.form?.value));

  }

  public openDialogNewsLetterDisable(): void {
    const dialogRef = this.dialog.open(DialogContentNewsLetterDisable, {
      width: '450px',
      data: { acceptNewsLetter: this.acceptNewsLetter, email: this.email },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result.email;

    });

  }

  public setConsent(value: any): void {
    localStorage.setItem('cookies_newsletter', value);
  }
}

import { createSelector, State } from "@ngrx/store";
import { FeatureStateName, ProductsState } from './products.reducer';


export const productsFeature = (state: any) => state.ProductsStateFromModule;

export const selectItems = createSelector(
  productsFeature,
  (state: ProductsState) => {
    return state.products
  }
);


export const getSelectedProduct =  createSelector(
  productsFeature,
  (state: ProductsState) => state.selectProduct
);

export const getItemById = (id: string) => createSelector(
  productsFeature,
  (state: ProductsState) => {
  if (state.products) {
    return state.products.find(item => {
      return item._id === id;
    });
  } else {
    return null;
  }
});

import { Action } from '@ngrx/store';
import { Setting } from 'src/app/models/setting.model';



export enum SettingActionsTypesNgrx {
  /*Get ALL SETTING*/
  GET_ALL_SETTING = '[Setting] Get All Settings',
  GET_ALL_SETTING_SUCCESS = '[Setting] Get All Settings Success',
  GET_ALL_SETTING_ERROR = '[Setting] Get All Settings Error',
  /*Get SEARCH SETTING*/
  SEARCH_SETTING = '[Setting] Get Search Settings',
  SEARCH_SETTING_SUCCESS = '[Setting] Get Search Settings Success',
  SEARCH_SETTING_ERROR = '[Setting] Get Search Settings Error',

  /*Get SEARCH SETTING*/
  GET_SETTING_BY_NAME = '[Setting] Get Setting by name',
  GET_SETTING_BY_NAME_SUCCESS = '[Setting] Get Setting by name Success',
  GET_SETTING_BY_NAME_ERROR = '[Setting] Get Setting by name Error',

  /*Get NEW SETTING*/
  NEW_SETTING = '[Setting] Get NEW Settings',
  NEW_SETTING_SUCCESS = '[Setting] Get NEW Settings Success',
  NEW_SETTING_ERROR = '[Setting] Get NEW Settings Error',

  /*Get SAVE SETTING*/
  SAVE_SETTING = '[Setting] Get SAVE Settings',
  SAVE_SETTING_SUCCESS = '[Setting] Get SAVE Settings Success',
  SAVE_SETTING_ERROR = '[Setting] Get SAVE Settings Error',

  /*Get EDIT SETTING*/
  EDIT_SETTING = '[Setting] Get EDIT Settings',
  EDIT_SETTING_SUCCESS = '[Setting] Get EDIT Settings Success',
  EDIT_SETTING_ERROR = '[Setting] Get EDIT Settings Error',

  /*Get UPDATE SETTING*/
  UPDATE_SETTING = '[Setting] Get UPDATE Settings',
  UPDATE_SETTING_SUCCESS = '[Setting] Get UPDATE Settings Success',
  UPDATE_SETTING_ERROR = '[Setting] Get UPDATE Settings Error',

  /*Get DELETE SETTING*/
  DELETE_SETTING = '[Setting] Get DELETE Settings',
  DELETE_SETTING_SUCCESS = '[Setting] Get DELETE Settings Success',
  DELETE_SETTING_ERROR = '[Setting] Get DELETE Settings Error',

}

/*Get ALL SETTING ACTIONS*/
export class GetAllSettingsAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_ALL_SETTING;
  constructor(public payload: any) { }
}

export class GetAllSettingsActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_ALL_SETTING_SUCCESS;
  constructor(public payload: Setting[]) { }
}

export class GetAllSettingsActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_ALL_SETTING_ERROR;
  constructor(public payload: string) { }
}

/*Get SETTING BY NAME ACTIONS*/
export class GetSettingByNameAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_SETTING_BY_NAME;
  constructor(public payload: any) { }
}

export class GetSettingByNameActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_SETTING_BY_NAME_SUCCESS;
  constructor(public payload: Setting) { }
}

export class GetSettingByNameActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.GET_SETTING_BY_NAME_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH SETTING ACTIONS*/
export class SearchSettingsAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SEARCH_SETTING;
  constructor(public payload: string) { }
}

export class SearchSettingsActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SEARCH_SETTING_SUCCESS;
  constructor(public payload: Setting[]) { }
}

export class SearchSettingsActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SEARCH_SETTING_ERROR;
  constructor(public payload: string) { }
}

/*Get DELETE SETTING ACTIONS*/
export class DeleteSettingAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.DELETE_SETTING;
  constructor(public payload: Setting) { }
}

export class DeleteSettingActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.DELETE_SETTING_SUCCESS;
  constructor(public payload: Setting) { }
}

export class DeleteSettingActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.DELETE_SETTING_ERROR;
  constructor(public payload: string) { }
}

/*Get NEW SETTING ACTIONS*/
export class NewSettingAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.NEW_SETTING;
  constructor(public payload: any) { }
}

export class NewSettingActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.NEW_SETTING_SUCCESS;
  constructor(public payload: any) { }
}

export class NewSettingActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.NEW_SETTING_ERROR;
  constructor(public payload: string) { }
}

/*Get SAVE SETTING ACTIONS*/
export class SaveSettingAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SAVE_SETTING;
  constructor(public payload: Setting) { }
}

export class SaveSettingActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SAVE_SETTING_SUCCESS;
  constructor(public payload: Setting) { }
}

export class SaveSettingActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.SAVE_SETTING_ERROR;
  constructor(public payload: string) { }
}

/*Get EDIT SETTING ACTIONS*/
export class EditSettingAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.EDIT_SETTING;
  constructor(public payload: string) { }
}

export class EditSettingActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.EDIT_SETTING_SUCCESS;
  constructor(public payload: Setting) { }
}

export class EditSettingActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.EDIT_SETTING_ERROR;
  constructor(public payload: string) { }
}


/*Get UPDATE SETTING ACTIONS*/
export class UpdateSettingAction implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.UPDATE_SETTING;
  constructor(public payload: Setting) { }
}

export class UpdateSettingActionSuccess implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.UPDATE_SETTING_SUCCESS;
  constructor(public payload: Setting) { }
}

export class UpdateSettingActionError implements Action {
  type: SettingActionsTypesNgrx = SettingActionsTypesNgrx.UPDATE_SETTING_ERROR;
  constructor(public payload: string) { }
}


export type SettingActions =
  GetAllSettingsAction | GetAllSettingsActionSuccess | GetAllSettingsActionError |

  SearchSettingsAction | SearchSettingsActionSuccess | SearchSettingsActionError |

  DeleteSettingAction | DeleteSettingActionSuccess | DeleteSettingActionError |
  NewSettingAction | NewSettingActionSuccess | NewSettingActionError |
  SaveSettingAction | SaveSettingActionSuccess | SaveSettingActionError |
  EditSettingAction | EditSettingActionSuccess | EditSettingActionError |
  UpdateSettingAction | UpdateSettingActionSuccess | UpdateSettingActionError;

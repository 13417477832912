import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CookieDialogComponent, DialogData } from "../cookie-dialog.component";

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
export class DialogContentExampleDialog {

  constructor(public dialogRef: MatDialogRef<CookieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}

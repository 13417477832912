export const environment = {
  production: true,
  host: 'https://promodeep.com/api',
  unreachableHost: 'http://localhost:8000',
  name: "prod",
  GA_TRACKING_ID: 'G-B0XK465P72',
  firebase : {
    apiKey: "AIzaSyCblK8yl2Zbd3ymNrrF6ZuUPmRCywzIHRM",
    authDomain: "promodeep-d0a9d.firebaseapp.com",
    projectId: "promodeep-d0a9d",
    storageBucket: "promodeep-d0a9d.appspot.com",
    messagingSenderId: "66865330207",
    appId: "1:66865330207:web:828d2552924bb901b6bd2a",
    measurementId: "G-CPTM90H16P"
  }
};

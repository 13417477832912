import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle("Politique de cookies - promodeep.com");
    this.metaTagService.updateTag (
      { name:"description", content:"➤ Ces cookies sont utilisés par le réseau d'affiliation pour suivre les transactions que vous pourriez effectuer sur notre site Web. - promodeep.com"},
    );
  }

}

<div class="container" *ngIf="successSend == false">
  <div>
    <ng-container *ngIf="contactFormGroup">
      <form [formGroup]="contactFormGroup" (ngSubmit)="onSendContact()">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <h1>Contactez Nous</h1>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label class="form-label">Sujet:</label>
            <select required  type="text" id="subject" name="subject" formControlName="subject" class="form-control" [class.is-invalid]="subject.invalid && (subject.dirty || subject.touched)">
              <option value="">Choisissez votre sujet !</option>
              <option required  [label] ="contactType" *ngFor="let contactType of contactTypes" [value]="contactType">{{contactType}}</option>
            </select>
            <div *ngIf="subject.errors && subject.touched" class="invalid-feedback">
              <div *ngIf="subject.errors?.['required']">
                This field is required.
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label class="form-label">Pseudo:</label>
            <input  class="form-control" type="text" id="pseudo" name="pseudo" formControlName="pseudo" required minlength="1" maxlength="250" [class.is-invalid]="pseudo.invalid && (pseudo.dirty || pseudo.touched)">
            <div *ngIf="pseudo.invalid && (pseudo.dirty || pseudo.touched)" class="invalid-feedback">
              <div *ngIf="pseudo.errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="pseudo.errors?.['minlength']">
                This field must have at least 1 character.
              </div>
              <div *ngIf="pseudo.errors?.['maxlength']">
                This field must have at most 250 characters.
              </div>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label class="form-label">Email:</label>
            <input type="emailContact" id="emailContact" name="emailContact" formControlName="emailContact" required minlength="1" maxlength="250" class="form-control" [class.is-invalid]="emailContact.invalid && (emailContact.dirty || emailContact.touched)">
            <div *ngIf="emailContact.invalid && (emailContact.dirty || emailContact.touched)" class="invalid-feedback">
              <div *ngIf="emailContact.errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="emailContact.errors?.['minlength']">
                This field must have at least 1 character.
              </div>
              <div *ngIf="emailContact.errors?.['maxlength']">
                This field must have at most 250 characters.
              </div>
              <div *ngIf="!emailContact.errors?.['required'] && !emailContact.errors?.['minlength'] && !emailContact.errors?.['maxlength'] && emailContact.errors?.['emailValidator']">
                Invalid email format.
              </div>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label class="form-label">Message:</label>
            <textarea  class="form-control" type="text" id="messageReceived" name="messageReceived" formControlName="messageReceived" required minlength="1" maxlength="2500" [class.is-invalid]="messageReceived.invalid && (messageReceived.dirty || messageReceived.touched)"></textarea>
            <div *ngIf="messageReceived.invalid && (messageReceived.dirty || messageReceived.touched)" class="invalid-feedback">
              <div *ngIf="messageReceived.errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="messageReceived.errors?.['minlength']">
                This field must have at least 1 character.
              </div>
              <div *ngIf="messageReceived.errors?.['maxlength']">
                This field must have at most 2500 characters.
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <button [disabled]="contactFormGroup.invalid" type="submit" class="btn btn-success">ENVOYER</button>
          </div>
        </div>
      </form>
      <br>
      <!-- {{contactFormGroup.value | json}} -->
    </ng-container>
  </div>
</div>
<div class="container" *ngIf="successSend">
  <div>
    <br>
    <br>
    <br>
      <h1 align="center">Merci</h1>
        <p align="center">
          Nous traitons votre demande dans le bref delai.
        </p>
    <br>
    <br>
    <br>
    <br>
  </div>
</div>

<div class="dialog-letter">
  <h1 mat-dialog-title>Désinscription à la newsletter</h1>
  <mat-dialog-content class="mat-typography">
    <p>
      Si vous ne souhaitez plus recevoir les newsletter, renseignez votre adresse e-mail
    </p>

    <form [formGroup]="form">
      <mat-form-field appearance="standard">
        <input matInput placeholder="exemple@exemple.com" formControlName="email" />
      </mat-form-field>
    </form>
    <p> Vous recever un mail de confirmation dans les prochaines minutes.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false" mat-dialog-close>Fermer</button>
    <button type="submit" [disabled]="form.invalid" (click)="submit()" [mat-dialog-close]="false" cdkFocusInitial
      mat-raised-button> Valider </button>
  </mat-dialog-actions>
</div>

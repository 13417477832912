import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NewsletterService } from '../../services/newsletter.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { NewsletterActions, NewsletterActionsTypesNgrx, SaveNewsletterActionError, SaveNewsletterActionSuccess } from './newsletter.action';



@Injectable()
export class NewslettersEffects {
  constructor(
    private effectAction: Actions,
    private newsletterService: NewsletterService
  ) { }


  /*Get NEW NEWSLETTER ACTIONS*/
  // NewNewslettersSuccessEffect: Observable<NewsletterActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(NewsletterActionsTypesNgrx.NEW_NEWSLETTER),
  //     map((action: NewsletterActions) => {
  //       return new NewNewsletterActionSuccess({});
  //     })
  //   )
  // );

  /*Get SAVE NEWSLETTER ACTIONS*/
  SaveNewslettersSuccessEffect: Observable<NewsletterActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(NewsletterActionsTypesNgrx.SAVE_NEWSLETTER),
      mergeMap((action: NewsletterActions) => {
        return this.newsletterService
          .save(action.payload)
          .pipe(map((newsletter) => new SaveNewsletterActionSuccess(newsletter)),
            catchError((err) => of(new SaveNewsletterActionError(err)))

          );

      })
    )
  );
}

<div class="container">
  <div>
    <h1>Politique de confidentialité et de protection de la vie privée</h1>
    <br>
    <br>
    <!-- <span>
      Le Site www.promodeep.com est déclaré sous le numéro 1234567 auprès de la Commission Nationale Informatique et
      Libertés (CNIL).
    </span> -->
    <br>
    <br>
    <span>
      Les informations recueillies font l'objet d'un traitement informatique destiné à créer une base de données liée
      aux offres partagées en ligne sur notre palteforme.
    </span>
    <br>
    <br>
    <span>
      Nous vous invitons à consulter notre politique de confidentialité pour en savoir plus sur l'origine et l'usage des
      données de navigation traitées à l'occasion de votre consultation et utilisation du Site www.promodeep.com, et sur vos
      droits y afférent.
    </span>
    <br>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Source } from 'src/app/models/source';
import { SourceService } from 'src/app/services/source.service';

@Component({
  selector: 'app-product-by-source',
  templateUrl: './product-by-source.component.html',
  styleUrls: ['./product-by-source.component.scss']
})
export class ProductBySourceComponent implements OnInit {

  sourceName!: string;
  source!: Source;

  constructor(private activatedRoute: ActivatedRoute, private sourceService: SourceService) {
    this.sourceName = activatedRoute.snapshot.params.source;
  }

  ngOnInit(): void {
    if (!this.source) {
      this.sourceService.getSourceByName(this.sourceName).subscribe (source => {
        this.source = source;
      });
    }
  }
}

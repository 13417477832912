import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pathRoutingEnum } from 'src/assets/constantEnum';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {

  panelOpenState = false;

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }

  onDashboard() {
    this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
  }

  onFilterCategory(dataForm: any) {
    this.redirectTo(pathRoutingEnum.category.valueOf() + '/' + dataForm)
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  addSubscription(sub: PushSubscription) {
    return lastValueFrom(
      this.http.post('http://localhost:3000/products/subscription', { sub })
    );
  }

  notifications(data: string, keyNotification: string) {
    return lastValueFrom(
      this.http.post('http://localhost:3000/products/notifications/'+ keyNotification, { data})
    );
  }


  generateVAPIDKeys(){
    const host = environment.host;
    return this.http.get<any>(host +'/products/notifications/generateVAPIDKeys');
  }
}


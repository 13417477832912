<div class="container">
  <div>

    <h1>Conditions générales d'utilisation</h1>
    <br>
      L'utilisation du site www.promodeep.com implique l'acceptation pleine et entière des conditions générales
      d'utilisation décrites çi dessous.
    <br>
    <br>
    <h2>Droit d'auteur</h2>
    <br>
    <span>
      La marque Promodeep est enregistrée auprès de l'INPI.
    </span>
    <span>
      Les marques citées sont la propriété de leurs propriétaires. aucune représentation, reproduction
      Toute traduction, modification ou citation, totale ou partielle, par quelque moyen que ce soit, est strictement interdite.
      Sans l'autorisation de TechnoDeep France, sauf dans les cas prévus à l'article L.112-5 du Code de la Propriété Intellectuelle.
      Image non contractuelle.
    </span>
  </div>
</div>

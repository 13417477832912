import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HeaderStickyFrontOfficeComponent } from 'src/app/componenets/header-sticky/header-sticky-front-office/header-sticky-front-office.component';
import { DialogContentNewsLetter } from 'src/app/componenets/newsletter-dialog/dialog-content-newsletter';
import { PublicHomeComponent } from 'src/app/componenets/public-home/public-home.component';
import { pathRoutingEnum } from 'src/assets/constantEnum';

@Component({
  selector: 'app-nav-bar-footer-mobile',
  templateUrl: './nav-bar-footer-mobile.component.html',
  styleUrls: ['./nav-bar-footer-mobile.component.scss']
})
export class NavBarFooterMobileComponent implements OnInit {

  @Input() publicHomeComponent: PublicHomeComponent = new PublicHomeComponent(this.deviceService);
  @Input() headerStickyFrontOfficeComponent!: HeaderStickyFrontOfficeComponent;
  private static readonly DARK_THEME_CLASS = 'dark-theme';
  private static readonly DARK_THEME_LIGHT = 'light';
  private static readonly DARK_THEME_DARK = 'dark';
  darkTheme: boolean = false;
  acceptNewsLetter: boolean = false;
  email!: string;
  cookiesNewsletter!: string | null;
  isIOS: boolean = false;
  public theme!: string;

  constructor(private deviceService: DeviceDetectorService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private platform: Platform) {
    this.theme = this.document.documentElement.classList.contains(NavBarFooterMobileComponent.DARK_THEME_CLASS) ? NavBarFooterMobileComponent.DARK_THEME_DARK : NavBarFooterMobileComponent.DARK_THEME_LIGHT;
  }

  ngOnInit(): void {
    this.isIOS = this.platform.IOS;
    this.cookiesNewsletter = localStorage.getItem('cookies_newsletter');
    if (this.cookiesNewsletter == null || this.cookiesNewsletter === "") {
      this.cookiesNewsletter = "2";
      this.setConsent("2");
    }
  }

  onScrollToHeader() {
    if (this.headerStickyFrontOfficeComponent.isDisplaySearch) {
      this.publicHomeComponent.scrollToTop();
      this.headerStickyFrontOfficeComponent.isDisplaySearch = false;
      if (this.router.url === pathRoutingEnum.dashboard.valueOf()) {
        this.router.navigate([pathRoutingEnum.promotion.valueOf()]);
      } else {
        this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
      }
    } else if (this.router.url === pathRoutingEnum.dashboard.valueOf() || this.router.url === pathRoutingEnum.promotion.valueOf()) {
      {
        this.publicHomeComponent.scrollToTop();
      }
    } else {
      this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
    }
    this.headerStickyFrontOfficeComponent.search = "";

  }

  onDisplaySearch() {
    this.headerStickyFrontOfficeComponent.isDisplaySearch = true;
  }

  OpenMenuMobile() {
    if (this.router.url === pathRoutingEnum.menuMobile.valueOf()) {
      this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
    } else {
      this.redirectTo(pathRoutingEnum.menuMobile.valueOf())
    }
    this.headerStickyFrontOfficeComponent.isDisplaySearch = false;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }





  public selectDarkTheme(): void {
    this.document.documentElement.classList.add(NavBarFooterMobileComponent.DARK_THEME_CLASS);
    this.theme = NavBarFooterMobileComponent.DARK_THEME_DARK;
    this.darkTheme = true;
  }

  public selectLightTheme(): void {
    this.document.documentElement.classList.remove(NavBarFooterMobileComponent.DARK_THEME_CLASS);
    this.theme = NavBarFooterMobileComponent.DARK_THEME_LIGHT;
    this.darkTheme = false;
  }

  public openDialogNewsLetter(): void {
    const dialogRef = this.dialog.open(DialogContentNewsLetter, {
      width: '450px',
      data: { acceptNewsLetter: this.acceptNewsLetter, email: this.email },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.acceptNewsLetter = result.acceptNewsLetter;
      this.email = result.email;
      if (result === false) {
        this.setConsent("0");
        this.cookiesNewsletter = "0";
      } else {
        this.setConsent("1");
        this.cookiesNewsletter = "1";
      }
    });

  }

  public setConsent(value: any): void {
    localStorage.setItem('cookies_newsletter', value);
  }

}

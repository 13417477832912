import { Action } from '@ngrx/store';
import { Contact } from 'src/app/models/contact';



export enum ContactActionsTypesNgrx {
  /*Get ALL CONTACT*/
  GET_ALL_CONTACT = '[Contact] Get All Contacts',
  GET_ALL_CONTACT_SUCCESS = '[Contact] Get All Contacts Success',
  GET_ALL_CONTACT_ERROR = '[Contact] Get All Contacts Error',
  /*Get SEARCH CONTACT*/
  SEARCH_CONTACT = '[Contact] Get Search Contacts',
  SEARCH_CONTACT_SUCCESS = '[Contact] Get Search Contacts Success',
  SEARCH_CONTACT_ERROR = '[Contact] Get Search Contacts Error',

  /*Get SEARCH CONTACT*/
  GET_CONTACT_BY_NAME = '[Contact] Get Contact by name',
  GET_CONTACT_BY_NAME_SUCCESS = '[Contact] Get Contact by name Success',
  GET_CONTACT_BY_NAME_ERROR = '[Contact] Get Contact by name Error',

  /*Get NEW CONTACT*/
  NEW_CONTACT = '[Contact] Get NEW Contacts',
  NEW_CONTACT_SUCCESS = '[Contact] Get NEW Contacts Success',
  NEW_CONTACT_ERROR = '[Contact] Get NEW Contacts Error',

  /*Get SAVE CONTACT*/
  SAVE_CONTACT = '[Contact] Get SAVE Contacts',
  SAVE_CONTACT_SUCCESS = '[Contact] Get SAVE Contacts Success',
  SAVE_CONTACT_ERROR = '[Contact] Get SAVE Contacts Error',

  /*Get EDIT CONTACT*/
  EDIT_CONTACT = '[Contact] Get EDIT Contacts',
  EDIT_CONTACT_SUCCESS = '[Contact] Get EDIT Contacts Success',
  EDIT_CONTACT_ERROR = '[Contact] Get EDIT Contacts Error',

  /*Get UPDATE CONTACT*/
  UPDATE_CONTACT = '[Contact] Get UPDATE Contacts',
  UPDATE_CONTACT_SUCCESS = '[Contact] Get UPDATE Contacts Success',
  UPDATE_CONTACT_ERROR = '[Contact] Get UPDATE Contacts Error',

  /*Get DELETE CONTACT*/
  DELETE_CONTACT = '[Contact] Get DELETE Contacts',
  DELETE_CONTACT_SUCCESS = '[Contact] Get DELETE Contacts Success',
  DELETE_CONTACT_ERROR = '[Contact] Get DELETE Contacts Error',

}

/*Get ALL CONTACT ACTIONS*/
export class GetAllContactsAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_ALL_CONTACT;
  constructor(public payload: any) { }
}

export class GetAllContactsActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_ALL_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class GetAllContactsActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_ALL_CONTACT_ERROR;
  constructor(public payload: string) { }
}

/*Get CONTACT BY NAME ACTIONS*/
export class GetContactByNameAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_CONTACT_BY_NAME;
  constructor(public payload: any) { }
}

export class GetContactByNameActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_CONTACT_BY_NAME_SUCCESS;
  constructor(public payload: Contact) { }
}

export class GetContactByNameActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.GET_CONTACT_BY_NAME_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH CONTACT ACTIONS*/
export class SearchContactsAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SEARCH_CONTACT;
  constructor(public payload: string) { }
}

export class SearchContactsActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SEARCH_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class SearchContactsActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SEARCH_CONTACT_ERROR;
  constructor(public payload: string) { }
}

/*Get DELETE CONTACT ACTIONS*/
export class DeleteContactAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.DELETE_CONTACT;
  constructor(public payload: Contact) { }
}

export class DeleteContactActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.DELETE_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class DeleteContactActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.DELETE_CONTACT_ERROR;
  constructor(public payload: string) { }
}

/*Get NEW CONTACT ACTIONS*/
export class NewContactAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.NEW_CONTACT;
  constructor(public payload: any) { }
}

export class NewContactActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.NEW_CONTACT_SUCCESS;
  constructor(public payload: any) { }
}

export class NewContactActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.NEW_CONTACT_ERROR;
  constructor(public payload: string) { }
}

/*Get SAVE CONTACT ACTIONS*/
export class SaveContactAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SAVE_CONTACT;
  constructor(public payload: Contact) { }
}

export class SaveContactActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SAVE_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class SaveContactActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.SAVE_CONTACT_ERROR;
  constructor(public payload: string) { }
}

/*Get EDIT CONTACT ACTIONS*/
export class EditContactAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.EDIT_CONTACT;
  constructor(public payload: string) { }
}

export class EditContactActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.EDIT_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class EditContactActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.EDIT_CONTACT_ERROR;
  constructor(public payload: string) { }
}


/*Get UPDATE CONTACT ACTIONS*/
export class UpdateContactAction implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.UPDATE_CONTACT;
  constructor(public payload: Contact) { }
}

export class UpdateContactActionSuccess implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.UPDATE_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateContactActionError implements Action {
  type: ContactActionsTypesNgrx = ContactActionsTypesNgrx.UPDATE_CONTACT_ERROR;
  constructor(public payload: string) { }
}


export type ContactActions =
  GetAllContactsAction | GetAllContactsActionSuccess | GetAllContactsActionError |

  SearchContactsAction | SearchContactsActionSuccess | SearchContactsActionError |

  DeleteContactAction | DeleteContactActionSuccess | DeleteContactActionError |
  NewContactAction | NewContactActionSuccess | NewContactActionError |
  SaveContactAction | SaveContactActionSuccess | SaveContactActionError |
  EditContactAction | EditContactActionSuccess | EditContactActionError |
  UpdateContactAction | UpdateContactActionSuccess | UpdateContactActionError;

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Source } from '../models/source';



@Injectable({
  providedIn: 'root'
})
export class SourceService {

  constructor(private httpClient: HttpClient) { }

  getSourceByName(name: string): Observable<Source> {
    const host = environment.host;
      return this.httpClient.get<Source>(host + '/sources/getSourceByName/' + name, {headers: {'http2':'true'}});
  }
}

<div class="container">
  <div>
    <h1>POLITIQUE DE COOKIES</h1>
    <br>
    <h2>Informations sur notre utilisation des cookies</h2>
    <br>
    <br>
    Le site promodeep.com utilise des cookies et des techniques similaires pour stocker des informations sur votre ordinateur
    ou appareil mobile. Nous utilisons les cookies suivants : Cookies de performance : Ces cookies nous aident à
    comprendre comment nos clients utilisent notre site Web.
    <br>
    <br>

    Cookies de ciblage : Ces cookies sont utilisés pour afficher des publicités de tiers sur d'autres sites,
    applications ou réseaux sociaux.
    <br>
    <br>

    Cookies d'affiliation : Ces cookies sont utilisés par le réseau d'affiliation pour suivre les transactions que vous
    pourriez effectuer sur notre site Web.
    <br>
    <br>

    Dans certains cas, cela nous permet de toucher une petite commission.
    <br>
    <br>
    Cookies de réseaux sociaux : Ces cookies sont utilisés pour s'intégrer aux réseaux sociaux. Cookies tiers des tiers
    peuvent également utiliser des cookies, sur lesquels nous n'avons aucun contrôle.
  </div>
</div>

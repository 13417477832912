import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact';



@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient) { }

  save(contact: Contact): Observable<Contact> {
    const host = environment.host;
    return this.httpClient.post<Contact>(host + '/contacts/', contact, {headers: {'http2':'true'}});
  }

}

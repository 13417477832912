import { Action } from '@ngrx/store';
import { Source } from 'src/app/models/source';



export enum SourceActionsTypesNgrx {
  /*Get ALL SOURCE*/
  GET_ALL_SOURCE = '[Source] Get All Sources',
  GET_ALL_SOURCE_SUCCESS = '[Source] Get All Sources Success',
  GET_ALL_SOURCE_ERROR = '[Source] Get All Sources Error',
  /*Get SEARCH SOURCE*/
  SEARCH_SOURCE = '[Source] Get Search Sources',
  SEARCH_SOURCE_SUCCESS = '[Source] Get Search Sources Success',
  SEARCH_SOURCE_ERROR = '[Source] Get Search Sources Error',

  /*Get SEARCH SOURCE*/
  GET_SOURCE_BY_NAME = '[Source] Get Source by name',
  GET_SOURCE_BY_NAME_SUCCESS = '[Source] Get Source by name Success',
  GET_SOURCE_BY_NAME_ERROR = '[Source] Get Source by name Error',

  /*Get NEW SOURCE*/
  NEW_SOURCE = '[Source] Get NEW Sources',
  NEW_SOURCE_SUCCESS = '[Source] Get NEW Sources Success',
  NEW_SOURCE_ERROR = '[Source] Get NEW Sources Error',

  /*Get SAVE SOURCE*/
  SAVE_SOURCE = '[Source] Get SAVE Sources',
  SAVE_SOURCE_SUCCESS = '[Source] Get SAVE Sources Success',
  SAVE_SOURCE_ERROR = '[Source] Get SAVE Sources Error',

  /*Get EDIT SOURCE*/
  EDIT_SOURCE = '[Source] Get EDIT Sources',
  EDIT_SOURCE_SUCCESS = '[Source] Get EDIT Sources Success',
  EDIT_SOURCE_ERROR = '[Source] Get EDIT Sources Error',

  /*Get UPDATE SOURCE*/
  UPDATE_SOURCE = '[Source] Get UPDATE Sources',
  UPDATE_SOURCE_SUCCESS = '[Source] Get UPDATE Sources Success',
  UPDATE_SOURCE_ERROR = '[Source] Get UPDATE Sources Error',

  /*Get DELETE SOURCE*/
  DELETE_SOURCE = '[Source] Get DELETE Sources',
  DELETE_SOURCE_SUCCESS = '[Source] Get DELETE Sources Success',
  DELETE_SOURCE_ERROR = '[Source] Get DELETE Sources Error',

}

/*Get ALL SOURCE ACTIONS*/
export class GetAllSourcesAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_ALL_SOURCE;
  constructor(public payload: any) { }
}

export class GetAllSourcesActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_ALL_SOURCE_SUCCESS;
  constructor(public payload: Source[]) { }
}

export class GetAllSourcesActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_ALL_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get SOURCE BY NAME ACTIONS*/
export class GetSourceByNameAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_SOURCE_BY_NAME;
  constructor(public payload: any) { }
}

export class GetSourceByNameActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_SOURCE_BY_NAME_SUCCESS;
  constructor(public payload: Source) { }
}

export class GetSourceByNameActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.GET_SOURCE_BY_NAME_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH SOURCE ACTIONS*/
export class SearchSourcesAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SEARCH_SOURCE;
  constructor(public payload: string) { }
}

export class SearchSourcesActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SEARCH_SOURCE_SUCCESS;
  constructor(public payload: Source[]) { }
}

export class SearchSourcesActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SEARCH_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get DELETE SOURCE ACTIONS*/
export class DeleteSourceAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.DELETE_SOURCE;
  constructor(public payload: Source) { }
}

export class DeleteSourceActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.DELETE_SOURCE_SUCCESS;
  constructor(public payload: Source) { }
}

export class DeleteSourceActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.DELETE_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get NEW SOURCE ACTIONS*/
export class NewSourceAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.NEW_SOURCE;
  constructor(public payload: any) { }
}

export class NewSourceActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.NEW_SOURCE_SUCCESS;
  constructor(public payload: any) { }
}

export class NewSourceActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.NEW_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get SAVE SOURCE ACTIONS*/
export class SaveSourceAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SAVE_SOURCE;
  constructor(public payload: Source) { }
}

export class SaveSourceActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SAVE_SOURCE_SUCCESS;
  constructor(public payload: Source) { }
}

export class SaveSourceActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.SAVE_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get EDIT SOURCE ACTIONS*/
export class EditSourceAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.EDIT_SOURCE;
  constructor(public payload: string) { }
}

export class EditSourceActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.EDIT_SOURCE_SUCCESS;
  constructor(public payload: Source) { }
}

export class EditSourceActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.EDIT_SOURCE_ERROR;
  constructor(public payload: string) { }
}


/*Get UPDATE SOURCE ACTIONS*/
export class UpdateSourceAction implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.UPDATE_SOURCE;
  constructor(public payload: Source) { }
}

export class UpdateSourceActionSuccess implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.UPDATE_SOURCE_SUCCESS;
  constructor(public payload: Source) { }
}

export class UpdateSourceActionError implements Action {
  type: SourceActionsTypesNgrx = SourceActionsTypesNgrx.UPDATE_SOURCE_ERROR;
  constructor(public payload: string) { }
}


export type SourceActions =
  GetAllSourcesAction | GetAllSourcesActionSuccess | GetAllSourcesActionError |

  SearchSourcesAction | SearchSourcesActionSuccess | SearchSourcesActionError |

  DeleteSourceAction | DeleteSourceActionSuccess | DeleteSourceActionError |
  NewSourceAction | NewSourceActionSuccess | NewSourceActionError |
  SaveSourceAction | SaveSourceActionSuccess | SaveSourceActionError |
  EditSourceAction | EditSourceActionSuccess | EditSourceActionError |
  UpdateSourceAction | UpdateSourceActionSuccess | UpdateSourceActionError;

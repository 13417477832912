import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pathRoutingEnum } from 'src/assets/constantEnum';
import { CookieDialogComponent } from '../componenets/cookie-dialog/cookie-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logo = 'assets/logo4.png';

  @Input()
  public cookieComponent!: CookieDialogComponent;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  year = new Date().getFullYear();

  onGoDashboard (){
    this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
  }

  showCookieDialog() {
    this.cookieComponent.showCookieDialog();
  }

}

import { Action } from '@ngrx/store';
import { Setting } from 'src/app/models/setting.model';
import { SettingActions, SettingActionsTypesNgrx } from './settings.action';


export  const FeatureStateName = 'SettingsStateFromModule';

export enum SettingsStateEnum {
  LOADING= 'LOADING',
  LOADED= 'LOADED',
  ERROR= 'ERROR',
  INITIAL= 'INITIAL',
  NEW= 'NEW',
  EDIT= 'EDIT',
  UPDATED= 'UPDATED'
}

export interface SettingsState {

  settings: Array<Setting>;
  selectSetting: Setting | null;
  errorMessage: string;
  dataStateNgrx: SettingsStateEnum;
  currentSetting: Setting|null;
  currentAction: SettingActions|null;
}

const initState: SettingsState = {
  settings: [],
  selectSetting: null,
  errorMessage: '',
  dataStateNgrx: SettingsStateEnum.INITIAL,
  currentSetting: null,
  currentAction:null

};

export function settingsReducer (state: SettingsState= initState, action: Action): SettingsState {

    switch (action.type) {

    /*Get SETTING*/
    case SettingActionsTypesNgrx.GET_ALL_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.GET_ALL_SETTING_SUCCESS:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, settings: (<SettingActions>action).payload, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.GET_ALL_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get SEARCH SETTING*/
    case SettingActionsTypesNgrx.SEARCH_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING};
    case SettingActionsTypesNgrx.SEARCH_SETTING_SUCCESS:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, settings: (<SettingActions>action).payload, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.SEARCH_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get SETTING BY NAME*/
    case SettingActionsTypesNgrx.GET_SETTING_BY_NAME:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING};
    case SettingActionsTypesNgrx.GET_SETTING_BY_NAME_SUCCESS:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, currentSetting: (<SettingActions>action).payload, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.GET_SETTING_BY_NAME_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get NEW SETTING*/
    case SettingActionsTypesNgrx.NEW_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.NEW_SETTING_SUCCESS:
    return {...state, dataStateNgrx : SettingsStateEnum.NEW, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.NEW_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get SAVE SETTING*/
    case SettingActionsTypesNgrx.SAVE_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.SAVE_SETTING_SUCCESS:
      const prods: Setting[] = [...state.settings];
      prods.push((<SettingActions>action).payload);
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, settings: prods, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.SAVE_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get EDIT SETTING*/
    case SettingActionsTypesNgrx.EDIT_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.EDIT_SETTING_SUCCESS:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, currentSetting: (<SettingActions>action).payload, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.EDIT_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    /*Get UPDATE SETTING*/
    case SettingActionsTypesNgrx.UPDATE_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.UPDATE_SETTING_SUCCESS:
    const settingUpdated: Setting = (<SettingActions>action).payload;
    const settingListUpdatedt = state.settings.map(p => (p._id === settingUpdated._id) ? settingUpdated : p);
    return {...state, dataStateNgrx : SettingsStateEnum.UPDATED, settings: settingListUpdatedt, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.UPDATE_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};


    /*Get DELETE SETTING*/
    case SettingActionsTypesNgrx.DELETE_SETTING:
    return {...state, dataStateNgrx : SettingsStateEnum.LOADING, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.DELETE_SETTING_SUCCESS:
    const settingDelete: Setting = (<SettingActions>action).payload;
    const index = state.settings.indexOf(settingDelete);
    const list = [...state.settings];
    list.splice(index, 1);
    const dataSetting: Setting[] = list.map(p => p._id === settingDelete._id ? settingDelete : p);
    return {...state, dataStateNgrx : SettingsStateEnum.LOADED, settings: dataSetting, currentAction: <SettingActions>action};
    case SettingActionsTypesNgrx.DELETE_SETTING_ERROR:
    return {...state, dataStateNgrx : SettingsStateEnum.ERROR, errorMessage: (<SettingActions>action).payload, currentAction: <SettingActions>action};

    default : return {...state};
  }
}

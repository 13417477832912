import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, EventEmitter, HostBinding, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  throttleTime
} from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { categoryEnum, pathRoutingEnum } from 'src/assets/constantEnum';
import { MatAccordion } from '@angular/material/expansion';
import { DeviceDetectorService } from 'ngx-device-detector';

import { TypeProductService } from 'src/app/services/type-product.service';
import { BrandProductService } from 'src/app/services/brand-product.service';
import { ReferenceProductService } from 'src/app/services/reference-product.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';
import { CookieService } from 'src/app/services/cookie.service';
import { Store } from '@ngrx/store';
import { SearchProductsAction } from 'src/app/ngrx/products.action';
import { ProductsState } from 'src/app/ngrx/products.reducer';
import { SearchProductDto } from '../../../models/search-product-dto';
import { TypeProduct } from 'src/app/models/type-product';
import { BrandProduct } from 'src/app/models/brand-product';
import { ReferenceProduct } from 'src/app/models/reference-product';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentNewsLetter } from '../../newsletter-dialog/dialog-content-newsletter';


enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

// @Component({
//   selector: 'app-header-sticky',
//   templateUrl: './header-sticky.component.html',
//   styleUrls: ['./header-sticky.component.scss']
// })

export interface DialogData {
  acceptNewsLetter: boolean;
  email: any;
}


@Component({
  selector: 'app-header-sticky-front-office',
  templateUrl: './header-sticky-front-office.component.html',
  styleUrls: ['./header-sticky-front-office.component.scss'],
  /*styles: [
    `
      :host {
        position: fixed;
        top: 0;
        width: 100%;
      }
    `
  ],*/
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('200ms ease-in'))
    ])
  ]
})

export class HeaderStickyFrontOfficeComponent implements OnInit {

  logo = 'assets/logo4.png';
  search: String = "";
  public constantEnum: typeof categoryEnum = categoryEnum
  indexTabChangeEvent: any;
  mobile: boolean = false;
  diplaySearchMobile: boolean = false;
  products!: any;
  searchProducts!: SearchProductDto[];
  searchProductDto!: SearchProductDto;
  deviceInfo!: any;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  isDisplaySearch: boolean = false;
  limit: number = 10;
  skip: number = 0;
  state: ProductsState | null = null;
  acceptNewsLetter: boolean = false;
  email!: string;

  @Input('inputRole')
  inputRole !: any;

  @Output("outputRoleToParent") outputRoleToParent: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  isOpenNavbar: boolean = false;
  private static readonly DARK_THEME_CLASS = 'dark-theme';
  private static readonly DARK_THEME_LIGHT = 'light';
  private static readonly DARK_THEME_DARK = 'dark';

  public theme: string;
  darkTheme: boolean = false;
  cookiesNewsletter!: string | null;
  boolnavCategories: boolean = false;



  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private deviceService: DeviceDetectorService,
    private typeProductService: TypeProductService,
    private brandProductService: BrandProductService,
    private referenceProductService: ReferenceProductService,
    public cookie: CookieService,
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    public dialog: MatDialog) {
    this.epicFunction();
    this.theme = this.document.documentElement.classList.contains(HeaderStickyFrontOfficeComponent.DARK_THEME_CLASS) ? HeaderStickyFrontOfficeComponent.DARK_THEME_DARK : HeaderStickyFrontOfficeComponent.DARK_THEME_LIGHT;
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .subscribe((state: BreakpointState) => {

        if (state.matches) {
          this.mobile = false;

        } else {
          this.mobile = true;
        }
      });
    // this.store.subscribe(myStore => {
    //   this.state = myStore.ProductsStateFromModule;
    // });
    this.cookiesNewsletter = localStorage.getItem('cookies_newsletter');
    if (this.cookiesNewsletter == null || this.cookiesNewsletter === "") {
      this.cookiesNewsletter = "2";
      this.setConsent("2");
    }

  }

  getBoolean(value: any) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  ngAfterViewInit(): void {
    if (this.isMobile == true || this.isTablet) {
      // document.getElementsByClassName('mat-tab-header-pagination-before')[0].remove();
      // document.getElementsByClassName('mat-tab-header-pagination-after')[0].remove();
      document.querySelector<HTMLInputElement>(".mat-tab-link-container")!.style.overflow = 'scroll';
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  opened: boolean = false;
  toggleMenu() {

    this.opened = !this.opened;
  }

  onSearchAutoComplete(dataForm: any) {
    this.searchProducts = [];
    if (dataForm.searchKeyWords && dataForm.searchKeyWords.length > 2) {
      this.typeProductService.searchTypeProduct(dataForm.searchKeyWords).subscribe(searchTypeProducts => {
        for (let i = 0; i < searchTypeProducts.length; i++) {
          this.searchProductDto = {
            _id: searchTypeProducts[i]._id,
            searchProduct: searchTypeProducts[i].type,
            description: searchTypeProducts[i].description,
            pictureUrl: searchTypeProducts[i].pictureUrl,
          }

          this.searchProducts.push(this.searchProductDto);
        }
      });
      this.brandProductService.searchBrandProduct(dataForm.searchKeyWords).subscribe(searchBrandProducts => {
        for (let i = 0; i < searchBrandProducts.length; i++) {
          this.searchProductDto = {
            _id: searchBrandProducts[i]._id,
            searchProduct: searchBrandProducts[i].brand,
            description: searchBrandProducts[i].description,
            pictureUrl: searchBrandProducts[i].pictureUrl,
          }
          this.searchProducts.push(this.searchProductDto);
        }
      });
      this.referenceProductService.searchReferenceProduct(dataForm.searchKeyWords).subscribe(searchReferenceProducts => {
        for (let i = 0; i < searchReferenceProducts.length; i++) {
          this.searchProductDto = {
            _id: searchReferenceProducts[i]._id,
            searchProduct: searchReferenceProducts[i].reference,
            description: searchReferenceProducts[i].description,
            pictureUrl: searchReferenceProducts[i].pictureUrl,
          }
          this.searchProducts.push(this.searchProductDto);
        }
      });
      // this.isOpenNavbar = false;
      // if(this.mobile && !this.isMobile && !this.isTablet) {
      //   this.accordion.closeAll();
      // }
    }
  }

  onSearch(dataForm: any) {
    setTimeout(async () => {
      this.searchProducts = [];
      let data = {
        limit: this.limit,
        skip: this.skip
      }
      // this.productService.searchProducts (dataForm.searchKeyWords, data ).subscribe (products => {
      //   //this.products = products
      //       //this.dashboardComponent.products = products;
      // });
      //this.dashboardComponent.onSearch(dataForm.searchKeyWords);
      let searchKeyWords = dataForm.searchKeyWords;
      this.store.dispatch(new SearchProductsAction({ searchKeyWords, data }));
      this.store.subscribe((myStore) => {
        if (myStore.ProductsStateFromModule) {
          this.state = myStore.ProductsStateFromModule;
          this.products = this.state?.products;

        }
      });
      // this.isOpenNavbar = false;
      // if(this.mobile && !this.isMobile && !this.isTablet) {
      //   this.accordion.closeAll();
      // }
    }, 1000);
  }

  onClickToSearch() {
    this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
  }

  onDashboard() {
    this.boolnavCategories = false;
    this.search = "";
    if (this.router.url === pathRoutingEnum.dashboard.valueOf()) {
      this.router.navigate([pathRoutingEnum.promotion.valueOf()]);
    } else {
      this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
    }

  }

  onFilterCategory(dataForm: any) {
    this.boolnavCategories = false;
    this.redirectTo(pathRoutingEnum.category.valueOf() + '/' + dataForm)
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  diplayNavCategories() {
    this.boolnavCategories = !this.boolnavCategories;
  }

  OpenMenuMobile() {
    this.redirectTo(pathRoutingEnum.menuMobile.valueOf())
  }

  // tabChanged(tabChangeEvent: MatTabChangeEvent): void {
  //   this.indexTabChangeEvent = tabChangeEvent.index;
  //   if (tabChangeEvent.index === 0) {
  //     this.onDashboard();
  //   } else if (tabChangeEvent.index === 1) {
  //     this.onFilterCategory(categoryEnum.electro);
  //   } else if (tabChangeEvent.index === 2) {
  //     this.onFilterCategory(categoryEnum.videoGame);
  //   } else if (tabChangeEvent.index === 3) {
  //     this.onFilterCategory(categoryEnum.fashion);
  //   } else if (tabChangeEvent.index === 4) {
  //     this.onFilterCategory(categoryEnum.cosmetic);
  //   } else if (tabChangeEvent.index === 5) {
  //     this.onFilterCategory(categoryEnum.sport);
  //   } else if (tabChangeEvent.index === 6) {
  //     this.onFilterCategory(categoryEnum.family);
  //   } else if (tabChangeEvent.index === 7) {
  //     this.onFilterCategory(categoryEnum.home);
  //   } else if (tabChangeEvent.index === 8) {
  //     this.onFilterCategory(categoryEnum.trip);
  //   } else if (tabChangeEvent.index === 9) {
  //     this.onFilterCategory(categoryEnum.mechanic);
  //   } else if (tabChangeEvent.index === 10) {
  //     this.onFilterCategory(categoryEnum.gardenDIY);
  //   } else if (tabChangeEvent.index === 11) {
  //     this.onFilterCategory(categoryEnum.service);
  //   } else if (tabChangeEvent.index === 12) {
  //     this.onFilterCategory(categoryEnum.featuredBrand);
  //   } else if (tabChangeEvent.index === 13) {
  //     this.onFilterCategory(categoryEnum.training);
  //   } else if (tabChangeEvent.index === 14) {
  //     this.onFilterCategory(categoryEnum.deal);
  //   } else if (tabChangeEvent.index === 15) {
  //     this.onFilterCategory(categoryEnum.finance);
  //   } else if (tabChangeEvent.index === 16) {
  //     this.onFilterCategory(categoryEnum.grocery);
  //   } else if (tabChangeEvent.index === 17) {
  //     this.onFilterCategory(categoryEnum.crypto);
  //   } else if (tabChangeEvent.index === 18) {
  //     this.onFilterCategory(categoryEnum.free);
  //   }
  //   // this.isOpenNavbar = false;
  //   // if(this.mobile && !this.isMobile && !this.isTablet) {
  //   //   this.accordion.closeAll();
  //   // }
  //   this.search = "";
  // }

  onFilterByPromotionCode() {
    this.boolnavCategories = false;
    this.redirectTo(pathRoutingEnum.promotionCode.valueOf());
    this.search = "";
  }

  private isVisible = true;

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }


  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // this.isOpenNavbar = false;
    // if(this.mobile && !this.isMobile && !this.isTablet) {
    //   this.accordion.closeAll();
    // }

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= 10) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
        distinctUntilChanged(),
        share()
      );

      const scrollUp$ = scroll$.pipe(
        filter(direction => direction === Direction.Up)
      );

      const scrollDown = scroll$.pipe(
        filter(direction => direction === Direction.Down)
      );

      scrollUp$.subscribe(() => (this.isVisible = true));
      scrollDown.subscribe(() => (this.isVisible = false));
    }

    if (scrollPosition < 10 && this.platform.IOS) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
        distinctUntilChanged(),
        share()
      );

      const scrollDown = scroll$.pipe(
        filter(direction => direction === Direction.Down)
      );

      scrollDown.subscribe(() => (this.isVisible = true));

    }

  }

  preventBehavior(e: any) {
    e.preventDefault();
  };


  // ngAfterViewInit() {
  //   const scroll$ = fromEvent(window, 'scroll').pipe(
  //     throttleTime(10),
  //     map(() => window.pageYOffset),
  //     pairwise(),
  //     map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
  //     distinctUntilChanged(),
  //     share()
  //   );

  //   const scrollUp$ = scroll$.pipe(
  //     filter(direction => direction === Direction.Up)
  //   );

  //   const scrollDown = scroll$.pipe(
  //     filter(direction => direction === Direction.Down)
  //   );

  //   scrollUp$.subscribe(() => (this.isVisible = true));
  //   scrollDown.subscribe(() => (this.isVisible = false));
  // }

  onGoDashboard() {
    this.router.navigate([pathRoutingEnum.dashboard.valueOf()]);
  }

  public selectDarkTheme(): void {
    this.document.documentElement.classList.add(HeaderStickyFrontOfficeComponent.DARK_THEME_CLASS);
    this.theme = HeaderStickyFrontOfficeComponent.DARK_THEME_DARK;
    this.darkTheme = true;
  }

  public selectLightTheme(): void {
    this.document.documentElement.classList.remove(HeaderStickyFrontOfficeComponent.DARK_THEME_CLASS);
    this.theme = HeaderStickyFrontOfficeComponent.DARK_THEME_LIGHT;
    this.darkTheme = false;
  }

  public openDialogNewsLetter(): void {
    const dialogRef = this.dialog.open(DialogContentNewsLetter, {
      width: '450px',
      data: { acceptNewsLetter: this.acceptNewsLetter, email: this.email },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.acceptNewsLetter = result.acceptNewsLetter;
      this.email = result.email;
      if (result === false) {
        this.setConsent("0");
        this.cookiesNewsletter = "0";
      } else {
        this.setConsent("1");
        this.cookiesNewsletter = "1";
      }
    });

  }

  public setConsent(value: any): void {
    localStorage.setItem('cookies_newsletter', value);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SettingService } from '../../services/setting.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  DeleteSettingActionError,
  DeleteSettingActionSuccess,
  GetAllSettingsActionError,
  GetAllSettingsActionSuccess,
  NewSettingActionSuccess,
  NewSettingActionError,
  SettingActions,
  SettingActionsTypesNgrx,
  SearchSettingsActionError,
  SearchSettingsActionSuccess,
  SaveSettingActionSuccess,
  SaveSettingActionError,
  EditSettingActionSuccess,
  EditSettingActionError,
  UpdateSettingActionError,
  UpdateSettingActionSuccess,
  GetSettingByNameActionError,
  GetSettingByNameActionSuccess,
} from './settings.action';


@Injectable()
export class SettingsEffects {
  constructor(
    private effectAction: Actions,
    private settingService: SettingService
  ) { }

  // getAllSettingsSuccessEffect: Observable<SettingActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(SettingActionsTypesNgrx.GET_ALL_SETTING),
  //     mergeMap((action: Actions) => {
  //       return this.settingService
  //         .getAllSettings()
  //         .pipe(map((settings) => new GetAllSettingsActionSuccess(settings)),
  //           catchError((err) => of(new GetAllSettingsActionError(err)))
  //         );
  //     })
  //   )
  // );


  /*Get NEW SETTING ACTIONS*/
  // NewSettingsSuccessEffect: Observable<SettingActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(SettingActionsTypesNgrx.NEW_SETTING),
  //     map((action: SettingActions) => {
  //       return new NewSettingActionSuccess({});
  //     })
  //   )
  // );
}

import { Action } from '@ngrx/store';
import { ProductDto } from '../models/productDto.model';
import { Product } from './../models/product.model';

export enum ProductActionsTypesNgrx {
  /*Get ALL PRODUCTS*/
  GET_ALL_PRODUCTS = '[Product] Get All Products',
  GET_ALL_PRODUCTS_SUCCESS = '[Product] Get All Products Success',
  GET_ALL_PRODUCTS_ERROR = '[Product] Get All Products Error',
  /*Get SELECTED PRODUCTS*/
  GET_SELECTED_PRODUCTS = '[Product] Get Selected Products',
  GET_SELECTED_PRODUCTS_SUCCESS = '[Product] Get Selected Products Success',
  GET_SELECTED_PRODUCTS_ERROR = '[Product] Get Selected Products Error',

  /*Get SEARCH PRODUCTS*/
  SEARCH_PRODUCTS = '[Product] Get Search Products',
  SEARCH_PRODUCTS_SUCCESS = '[Product] Get Search Products Success',
  SEARCH_PRODUCTS_ERROR = '[Product] Get Search Products Error',

  /*Get SEARCH BY CATEGORY PRODUCTS*/
  SEARCH_PRODUCTS_BY_CATEGORY = '[Product] Get Search Products By category',
  SEARCH_PRODUCTS_BY_CATEGORY_SUCCESS = '[Product] Get Search Products By category Success',
  SEARCH_PRODUCTS_BY_CATEGORY_ERROR = '[Product] Get Search Products By category Error',

  /*Get SEARCH BY SOURCE PRODUCTS*/
  SEARCH_PRODUCTS_BY_SOURCE = '[Product] Get Search Products By Source',
  SEARCH_PRODUCTS_BY_SOURCE_SUCCESS = '[Product] Get Search Products By Source Success',
  SEARCH_PRODUCTS_BY_SOURCE_ERROR = '[Product] Get Search Products By Source Error',

  /*Get SEARCH BY PROMOTION_CODE PRODUCTS*/
  SEARCH_PRODUCTS_BY_PROMOTION_CODE = '[Product] Get Search Products By Promotion Code',
  SEARCH_PRODUCTS_BY_PROMOTION_CODE_SUCCESS = '[Product] Get Search Products By Promotion Code Success',
  SEARCH_PRODUCTS_BY_PROMOTION_CODE_ERROR = '[Product] Get Search Products By Promotion Code Error',

  /*SET SELECT PRODUCTS*/
  SELECT_PRODUCTS = '[Product] PUT SELECT Products',
  SELECT_PRODUCTS_SUCCESS = '[Product] PUT SELECT Products Success',
  SELECT_PRODUCTS_ERROR = '[Product] PUT SELECT Products Error',

  /*SET AVAILABLE PRODUCTS*/
  SET_AVAILABLE_PRODUCTS = '[Product] SET AVAILABLE Products',
  SET_AVAILABLE_PRODUCTS_SUCCESS = '[Product] SET AVAILABLE Products Success',
  SET_AVAILABLE_PRODUCTS_ERROR = '[Product] SET AVAILABLE Products Error',

  /*Get SELECT PRODUCTS*/
  DELETE_PRODUCTS = '[Product] Get DELETE Products',
  DELETE_PRODUCTS_SUCCESS = '[Product] Get DELETE Products Success',
  DELETE_PRODUCTS_ERROR = '[Product] Get DELETE Products Error',

  /*Get NEW PRODUCTS*/
  NEW_PRODUCTS = '[Product] Get NEW Products',
  NEW_PRODUCTS_SUCCESS = '[Product] Get NEW Products Success',
  NEW_PRODUCTS_ERROR = '[Product] Get NEW Products Error',

  /*Get SAVE PRODUCTS*/
  SAVE_PRODUCTS = '[Product] Get SAVE Products',
  SAVE_PRODUCTS_SUCCESS = '[Product] Get SAVE Products Success',
  SAVE_PRODUCTS_ERROR = '[Product] Get SAVE Products Error',

  /*Get EDIT PRODUCTS*/
  EDIT_PRODUCTS = '[Product] Get EDIT Products',
  EDIT_PRODUCTS_SUCCESS = '[Product] Get EDIT Products Success',
  EDIT_PRODUCTS_ERROR = '[Product] Get EDIT Products Error',

  /*Get UPDATE PRODUCTS*/
  UPDATE_PRODUCTS = '[Product] Get UPDATE Products',
  UPDATE_PRODUCTS_SUCCESS = '[Product] Get UPDATE Products Success',
  UPDATE_PRODUCTS_ERROR = '[Product] Get UPDATE Products Error',

  /*Get FILTER CATEGORY*/
  FILTER_CATEGORY = '[Product] Get Filter Category',
  FILTER_CATEGORY_SUCCESS = '[Product] Get Filter Category Success',
  FILTER_CATEGORY_ERROR = '[Product] Get Filter Category Error',

  /*Get FILTER BY SOURCE*/
  FILTER_BY_SOURCE = '[Product] Get Filter By Source',
  FILTER_BY_SOURCE_SUCCESS = '[Product] Get Filter By Source Success',
  FILTER_BY_SOURCE_ERROR = '[Product] Get Filter By Source Error',

  /*Get FILTER BY CODE PROMO*/
  FILTER_BY_PROMO_CODE = '[Product] Get Filter By promotion code',
  FILTER_BY_PROMO_CODE_SUCCESS = '[Product] Get Filter By promotion code Success',
  FILTER_BY_PROMO_CODE_ERROR = '[Product] Get Filter By promotion code Error',

}

/*Get ALL PRODUCTS ACTIONS*/
export class GetAllProductsAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_ALL_PRODUCTS;
  constructor(public payload: any) { }
}

export class GetAllProductsActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_ALL_PRODUCTS_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class GetAllProductsActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_ALL_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get SELECTED PRODUCTS ACTIONS*/
export class GetSelectedProductsAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS;
  constructor(public payload: any) { }
}

export class GetSelectedProductsActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class GetSelectedProductsActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH PRODUCTS ACTIONS*/
export class SearchProductsAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS;
  constructor(public payload: any) { }
}

export class SearchProductsActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class SearchProductsActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}


/*Get SEARCH PRODUCTS BY CATEGORY ACTIONS*/
export class SearchProductsByCategoryAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY;
  constructor(public payload: ProductDto) { }
}

export class SearchProductsByCategoryActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class SearchProductsByCategoryActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH PRODUCTS BY SOURCE ACTIONS*/
export class SearchProductsBySourceAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE;
  constructor(public payload: ProductDto) { }
}

export class SearchProductsBySourceActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class SearchProductsBySourceActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH PRODUCTS BY PROMOTION_CODE ACTIONS*/
export class SearchProductsByPromotionCodeAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE;
  constructor(public payload: ProductDto) { }
}

export class SearchProductsByPromotionCodeActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class SearchProductsByPromotionCodeActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE_ERROR;
  constructor(public payload: string) { }
}

/*SET SELECT PRODUCTS ACTIONS*/
export class SelectProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SELECT_PRODUCTS;
  constructor(public payload: Product) {
  }
}

export class SelectProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SELECT_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class SelectProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SELECT_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*SET AVAILABLE PRODUCTS ACTIONS*/
export class SetAvailableProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS;
  constructor(public payload: Product) {
  }
}

export class SetAvailableProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class SetAvailableProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get DELETE PRODUCTS ACTIONS*/
export class DeleteProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.DELETE_PRODUCTS;
  constructor(public payload: Product) { }
}

export class DeleteProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.DELETE_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class DeleteProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.DELETE_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get NEW PRODUCTS ACTIONS*/
export class NewProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.NEW_PRODUCTS;
  constructor(public payload: any) { }
}

export class NewProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.NEW_PRODUCTS_SUCCESS;
  constructor(public payload: any) { }
}

export class NewProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.NEW_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get SAVE PRODUCTS ACTIONS*/
export class SaveProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SAVE_PRODUCTS;
  constructor(public payload: Product) { }
}

export class SaveProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SAVE_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class SaveProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.SAVE_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get EDIT PRODUCTS ACTIONS*/
export class EditProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.EDIT_PRODUCTS;
  constructor(public payload: string) { }
}

export class EditProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.EDIT_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class EditProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.EDIT_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}


/*Get UPDATE PRODUCTS ACTIONS*/
export class UpdateProductAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.UPDATE_PRODUCTS;
  constructor(public payload: Product) { }
}

export class UpdateProductActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.UPDATE_PRODUCTS_SUCCESS;
  constructor(public payload: Product) { }
}

export class UpdateProductActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.UPDATE_PRODUCTS_ERROR;
  constructor(public payload: string) { }
}

/*Get FILTER CATEGORY ACTIONS*/
export class FilterCategoryAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_CATEGORY;
  constructor(public payload: any) { }
}

export class FilterCategoryActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_CATEGORY_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class FilterCategoryActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_CATEGORY_ERROR;
  constructor(public payload: string) { }
}


/*Get FILTER BY SOURCE ACTIONS*/
export class FilterBySourceAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_SOURCE;
  constructor(public payload: any) { }
}

export class FilterBySourceActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_SOURCE_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class FilterBySourceActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_SOURCE_ERROR;
  constructor(public payload: string) { }
}

/*Get FILTER BY PROMOTION CODE ACTIONS*/
export class FilterByPromotionCodeAction implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE;
  constructor(public payload: any) { }
}

export class FilterByPromotionCodeActionSuccess implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE_SUCCESS;
  constructor(public payload: Product[]) { }
}

export class FilterByPromotionCodeActionError implements Action {
  type: ProductActionsTypesNgrx = ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE_ERROR;
  constructor(public payload: string) { }
}




export type ProductActions =
  GetAllProductsAction | GetAllProductsActionSuccess | GetAllProductsActionError |
  GetSelectedProductsAction | GetSelectedProductsActionSuccess | GetSelectedProductsActionError |
  SearchProductsAction | SearchProductsActionSuccess | SearchProductsActionError |
  SelectProductAction | SelectProductActionSuccess | SelectProductActionError |
  DeleteProductAction | DeleteProductActionSuccess | DeleteProductActionError |
  NewProductAction | NewProductActionSuccess | NewProductActionError |
  SaveProductAction | SaveProductActionSuccess | SaveProductActionError |
  EditProductAction | EditProductActionSuccess | EditProductActionError |
  UpdateProductAction | UpdateProductActionSuccess | UpdateProductActionError |
  FilterCategoryAction | FilterCategoryActionSuccess | FilterCategoryActionError;

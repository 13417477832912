import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Newsletter } from '../models/newsletter';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private httpClient: HttpClient) { }

  save(newsletter: Newsletter): Observable<Newsletter> {
    const host = environment.host;
    return this.httpClient.post<Newsletter>(host + '/newsletters/', newsletter, {headers: {'http2':'true'}});
  }

  update(newsletter: Newsletter): Observable<Newsletter> {
    const host = environment.host;

    return this.httpClient.put<Newsletter>(host + '/newsletters/fromFrontOffice/'  + newsletter.email, newsletter);
  }

}

import { Action } from '@ngrx/store';
import { Newsletter } from '../../models/newsletter';



export enum NewsletterActionsTypesNgrx {
  /*Get ALL NEWSLETTER*/
  GET_ALL_NEWSLETTER = '[Newsletter] Get All Newsletters',
  GET_ALL_NEWSLETTER_SUCCESS = '[Newsletter] Get All Newsletters Success',
  GET_ALL_NEWSLETTER_ERROR = '[Newsletter] Get All Newsletters Error',
  /*Get SEARCH NEWSLETTER*/
  SEARCH_NEWSLETTER = '[Newsletter] Get Search Newsletters',
  SEARCH_NEWSLETTER_SUCCESS = '[Newsletter] Get Search Newsletters Success',
  SEARCH_NEWSLETTER_ERROR = '[Newsletter] Get Search Newsletters Error',

  /*Get SEARCH NEWSLETTER*/
  GET_NEWSLETTER_BY_NAME = '[Newsletter] Get Newsletter by name',
  GET_NEWSLETTER_BY_NAME_SUCCESS = '[Newsletter] Get Newsletter by name Success',
  GET_NEWSLETTER_BY_NAME_ERROR = '[Newsletter] Get Newsletter by name Error',

  /*Get NEW NEWSLETTER*/
  NEW_NEWSLETTER = '[Newsletter] Get NEW Newsletters',
  NEW_NEWSLETTER_SUCCESS = '[Newsletter] Get NEW Newsletters Success',
  NEW_NEWSLETTER_ERROR = '[Newsletter] Get NEW Newsletters Error',

  /*Get SAVE NEWSLETTER*/
  SAVE_NEWSLETTER = '[Newsletter] Get SAVE Newsletters',
  SAVE_NEWSLETTER_SUCCESS = '[Newsletter] Get SAVE Newsletters Success',
  SAVE_NEWSLETTER_ERROR = '[Newsletter] Get SAVE Newsletters Error',

  /*Get EDIT NEWSLETTER*/
  EDIT_NEWSLETTER = '[Newsletter] Get EDIT Newsletters',
  EDIT_NEWSLETTER_SUCCESS = '[Newsletter] Get EDIT Newsletters Success',
  EDIT_NEWSLETTER_ERROR = '[Newsletter] Get EDIT Newsletters Error',

  /*Get UPDATE NEWSLETTER*/
  UPDATE_NEWSLETTER = '[Newsletter] Get UPDATE Newsletters',
  UPDATE_NEWSLETTER_SUCCESS = '[Newsletter] Get UPDATE Newsletters Success',
  UPDATE_NEWSLETTER_ERROR = '[Newsletter] Get UPDATE Newsletters Error',

  /*Get DELETE NEWSLETTER*/
  DELETE_NEWSLETTER = '[Newsletter] Get DELETE Newsletters',
  DELETE_NEWSLETTER_SUCCESS = '[Newsletter] Get DELETE Newsletters Success',
  DELETE_NEWSLETTER_ERROR = '[Newsletter] Get DELETE Newsletters Error',

}

/*Get ALL NEWSLETTER ACTIONS*/
export class GetAllNewslettersAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER;
  constructor(public payload: any) { }
}

export class GetAllNewslettersActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter[]) { }
}

export class GetAllNewslettersActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}

/*Get NEWSLETTER BY NAME ACTIONS*/
export class GetNewsletterByNameAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME;
  constructor(public payload: any) { }
}

export class GetNewsletterByNameActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME_SUCCESS;
  constructor(public payload: Newsletter) { }
}

export class GetNewsletterByNameActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME_ERROR;
  constructor(public payload: string) { }
}

/*Get SEARCH NEWSLETTER ACTIONS*/
export class SearchNewslettersAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER;
  constructor(public payload: string) { }
}

export class SearchNewslettersActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter[]) { }
}

export class SearchNewslettersActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}

/*Get DELETE NEWSLETTER ACTIONS*/
export class DeleteNewsletterAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.DELETE_NEWSLETTER;
  constructor(public payload: Newsletter) { }
}

export class DeleteNewsletterActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.DELETE_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter) { }
}

export class DeleteNewsletterActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.DELETE_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}

/*Get NEW NEWSLETTER ACTIONS*/
export class NewNewsletterAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.NEW_NEWSLETTER;
  constructor(public payload: any) { }
}

export class NewNewsletterActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.NEW_NEWSLETTER_SUCCESS;
  constructor(public payload: any) { }
}

export class NewNewsletterActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.NEW_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}

/*Get SAVE NEWSLETTER ACTIONS*/
export class SaveNewsletterAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SAVE_NEWSLETTER;
  constructor(public payload: Newsletter) { }
}

export class SaveNewsletterActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SAVE_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter) { }
}

export class SaveNewsletterActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.SAVE_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}

/*Get EDIT NEWSLETTER ACTIONS*/
export class EditNewsletterAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.EDIT_NEWSLETTER;
  constructor(public payload: string) { }
}

export class EditNewsletterActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.EDIT_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter) { }
}

export class EditNewsletterActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.EDIT_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}


/*Get UPDATE NEWSLETTER ACTIONS*/
export class UpdateNewsletterAction implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER;
  constructor(public payload: Newsletter) { }
}

export class UpdateNewsletterActionSuccess implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER_SUCCESS;
  constructor(public payload: Newsletter) { }
}

export class UpdateNewsletterActionError implements Action {
  type: NewsletterActionsTypesNgrx = NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER_ERROR;
  constructor(public payload: string) { }
}


export type NewsletterActions =
  GetAllNewslettersAction | GetAllNewslettersActionSuccess | GetAllNewslettersActionError |

  SearchNewslettersAction | SearchNewslettersActionSuccess | SearchNewslettersActionError |

  DeleteNewsletterAction | DeleteNewsletterActionSuccess | DeleteNewsletterActionError |
  NewNewsletterAction | NewNewsletterActionSuccess | NewNewsletterActionError |
  SaveNewsletterAction | SaveNewsletterActionSuccess | SaveNewsletterActionError |
  EditNewsletterAction | EditNewsletterActionSuccess | EditNewsletterActionError |
  UpdateNewsletterAction | UpdateNewsletterActionSuccess | UpdateNewsletterActionError;

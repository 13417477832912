import { Action } from '@ngrx/store';
import { Product } from './../models/product.model';
import { ProductActions, ProductActionsTypesNgrx } from './products.action';

export  const FeatureStateName = 'ProductsStateFromModule';

export enum ProductsStateEnum {
  LOADING= 'LOADING',
  LOADED= 'LOADED',
  ERROR= 'ERROR',
  INITIAL= 'INITIAL',
  NEW= 'NEW',
  EDIT= 'EDIT',
  UPDATED= 'UPDATED'
}

export interface ProductsState {

  products: Product[];
  selectProduct: Product | null;
  errorMessage: string;
  dataStateNgrx: ProductsStateEnum;
  currentProduct: Product|null;
  currentAction: ProductActions|null;
}

const initState: ProductsState = {
  products: [],
  selectProduct: null,
  errorMessage: '',
  dataStateNgrx: ProductsStateEnum.INITIAL,
  currentProduct: null,
  currentAction:null

};

export function productsReducer (state: ProductsState= initState, action: Action): ProductsState {

    switch (action.type) {

    /*Get PRODUCTS*/
    case ProductActionsTypesNgrx.GET_ALL_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.GET_ALL_PRODUCTS_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.GET_ALL_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get SELECTED PRODUCTS*/
    case ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get SEARCH PRODUCTS*/
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};


    /*Get SEARCH PRODUCTS BY CATEGORY*/
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get SEARCH PRODUCTS BY SOURCE*/
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get SEARCH PRODUCTS BY PROMOTION_CODE*/
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};


    /*SET SELECT PRODUCTS*/
    case ProductActionsTypesNgrx.SELECT_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SELECT_PRODUCTS_SUCCESS:
    const productSelect: Product = (<ProductActions>action).payload;
    const productList = [...state.products];
    const data: Product[] = productList.map(p => p._id === productSelect._id ? productSelect : p);
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: data, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SELECT_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

  /*SET AVAILABLE PRODUCTS*/
    case ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS_SUCCESS:
    const productAvailable: Product = (<ProductActions>action).payload;
    const productAvailableList = [...state.products];
    const dataAvailable: Product[] = productAvailableList.map(p => p._id === productAvailable._id ? productAvailable : p);
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: dataAvailable, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SET_AVAILABLE_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get DELETE PRODUCTS*/
    case ProductActionsTypesNgrx.DELETE_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.DELETE_PRODUCTS_SUCCESS:
    const productDelete: Product = (<ProductActions>action).payload;
    const index = state.products.indexOf(productDelete);
    const list = [...state.products];
    list.splice(index, 1);
    const dataProduct: Product[] = list.map(p => p._id === productDelete._id ? productDelete : p);
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: dataProduct, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.DELETE_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get NEW PRODUCTS*/
    case ProductActionsTypesNgrx.NEW_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.NEW_PRODUCTS_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.NEW, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.NEW_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get SAVE PRODUCTS*/
    case ProductActionsTypesNgrx.SAVE_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SAVE_PRODUCTS_SUCCESS:
      const prods: Product[] = [...state.products];
      prods.push((<ProductActions>action).payload);
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: prods, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.SAVE_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get EDIT PRODUCTS*/
    case ProductActionsTypesNgrx.EDIT_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.EDIT_PRODUCTS_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, currentProduct: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.EDIT_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get UPDATE PRODUCTS*/
    case ProductActionsTypesNgrx.UPDATE_PRODUCTS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.UPDATE_PRODUCTS_SUCCESS:
    const productUpdated: Product = (<ProductActions>action).payload;
    const productListUpdatedt = state.products.map(p => (p._id === productUpdated._id) ? productUpdated : p);
    return {...state, dataStateNgrx : ProductsStateEnum.UPDATED, products: productListUpdatedt, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.UPDATE_PRODUCTS_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get FILTER CATEGORY*/
    case ProductActionsTypesNgrx.FILTER_CATEGORY:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.FILTER_CATEGORY_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.FILTER_CATEGORY_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};


    /*Get FILTER CATEGORY*/
    case ProductActionsTypesNgrx.FILTER_BY_SOURCE:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING};
    case ProductActionsTypesNgrx.FILTER_BY_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.FILTER_BY_SOURCE_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};

    /*Get FILTER BY PROMOTION CODE */
    case ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADING, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE_SUCCESS:
    return {...state, dataStateNgrx : ProductsStateEnum.LOADED, products: (<ProductActions>action).payload, currentAction: <ProductActions>action};
    case ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE_ERROR:
    return {...state, dataStateNgrx : ProductsStateEnum.ERROR, errorMessage: (<ProductActions>action).payload, currentAction: <ProductActions>action};


    default : return {...state};
  }
}

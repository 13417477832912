import { Action } from '@ngrx/store';
import { ContactActions, ContactActionsTypesNgrx } from './contacts.action';
import { Contact } from '../../models/contact';

export  const FeatureStateName = 'ContactsStateFromModule';

export enum ContactsStateEnum {
  LOADING= 'LOADING',
  LOADED= 'LOADED',
  ERROR= 'ERROR',
  INITIAL= 'INITIAL',
  NEW= 'NEW',
  EDIT= 'EDIT',
  UPDATED= 'UPDATED'
}

export interface ContactsState {

  contacts: Array<Contact>;
  selectContact: Contact | null;
  errorMessage: string;
  dataStateNgrx: ContactsStateEnum;
  currentContact: Contact|null;
  currentAction: ContactActions|null;
}

const initState: ContactsState = {
  contacts: [],
  selectContact: null,
  errorMessage: '',
  dataStateNgrx: ContactsStateEnum.INITIAL,
  currentContact: null,
  currentAction:null

};

export function contactsReducer (state: ContactsState= initState, action: Action): ContactsState {

    switch (action.type) {

    /*Get CONTACT*/
    case ContactActionsTypesNgrx.GET_ALL_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.GET_ALL_CONTACT_SUCCESS:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, contacts: (<ContactActions>action).payload, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.GET_ALL_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get SEARCH CONTACT*/
    case ContactActionsTypesNgrx.SEARCH_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING};
    case ContactActionsTypesNgrx.SEARCH_CONTACT_SUCCESS:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, contacts: (<ContactActions>action).payload, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.SEARCH_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get CONTACT BY NAME*/
    case ContactActionsTypesNgrx.GET_CONTACT_BY_NAME:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING};
    case ContactActionsTypesNgrx.GET_CONTACT_BY_NAME_SUCCESS:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, currentContact: (<ContactActions>action).payload, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.GET_CONTACT_BY_NAME_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get NEW CONTACT*/
    case ContactActionsTypesNgrx.NEW_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.NEW_CONTACT_SUCCESS:
    return {...state, dataStateNgrx : ContactsStateEnum.NEW, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.NEW_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get SAVE CONTACT*/
    case ContactActionsTypesNgrx.SAVE_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.SAVE_CONTACT_SUCCESS:
      const prods: Contact[] = [...state.contacts];
      prods.push((<ContactActions>action).payload);
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, contacts: prods, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.SAVE_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get EDIT CONTACT*/
    case ContactActionsTypesNgrx.EDIT_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.EDIT_CONTACT_SUCCESS:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, currentContact: (<ContactActions>action).payload, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.EDIT_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    /*Get UPDATE CONTACT*/
    case ContactActionsTypesNgrx.UPDATE_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.UPDATE_CONTACT_SUCCESS:
    const contactUpdated: Contact = (<ContactActions>action).payload;
    const contactListUpdatedt = state.contacts.map(p => (p._id === contactUpdated._id) ? contactUpdated : p);
    return {...state, dataStateNgrx : ContactsStateEnum.UPDATED, contacts: contactListUpdatedt, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.UPDATE_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};


    /*Get DELETE CONTACT*/
    case ContactActionsTypesNgrx.DELETE_CONTACT:
    return {...state, dataStateNgrx : ContactsStateEnum.LOADING, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.DELETE_CONTACT_SUCCESS:
    const contactDelete: Contact = (<ContactActions>action).payload;
    const index = state.contacts.indexOf(contactDelete);
    const list = [...state.contacts];
    list.splice(index, 1);
    const dataContact: Contact[] = list.map(p => p._id === contactDelete._id ? contactDelete : p);
    return {...state, dataStateNgrx : ContactsStateEnum.LOADED, contacts: dataContact, currentAction: <ContactActions>action};
    case ContactActionsTypesNgrx.DELETE_CONTACT_ERROR:
    return {...state, dataStateNgrx : ContactsStateEnum.ERROR, errorMessage: (<ContactActions>action).payload, currentAction: <ContactActions>action};

    default : return {...state};
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './componenets/dashboard/dashboard.component';
import { AboutComponent } from './componenets/about/about.component';
import { PartnerComponent } from './componenets/partner/partner.component';
import { PrivacyPolicyComponent } from './componenets/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './componenets/cookies-policy/cookies-policy.component';
import { LegalNoticeComponent } from './componenets/legal-notice/legal-notice.component';
import { ContactComponent } from './componenets/contact-ngrx/contact/contact.component';
import { PublicHomeComponent } from './componenets/public-home/public-home.component';
import { ProductDetailsNgrxComponent } from './componenets/producs-ngrx/product-details-ngrx/product-details-ngrx.component';
import { GeneralPolicyComponent } from './componenets/general-policy/general-policy.component';
import { PromotionCodeComponent } from './componenets/dashboard/promotion-code/promotion-code.component';
import { ProductBySourceComponent } from './componenets/producs-ngrx/product-by-source/product-by-source.component';
import { MenuMobileComponent } from './footer/menu-mobile/menu-mobile.component';


const routes: Routes = [
  {
    path: '',
    component: PublicHomeComponent,
    children: [
      { path: '', component: DashboardComponent ,  pathMatch: 'full' },
      { path: 'promotion', component: DashboardComponent },
      { path: 'category/:category', component: DashboardComponent },
      { path: 'source/:source', component: ProductBySourceComponent },
      { path: 'promotionCode', component: PromotionCodeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'partner', component: PartnerComponent },
      { path: 'privacyPolicy', component: PrivacyPolicyComponent },
      { path: 'generalPolicy', component: GeneralPolicyComponent },
      { path: 'cookies', component: CookiesPolicyComponent },
      { path: 'legalNotice', component: LegalNoticeComponent },
      { path: 'product/:id', component: ProductDetailsNgrxComponent, /* resolve: ProductDetailResolver*/ },
      { path: 'contact', component: ContactComponent },
      { path: 'menuMobile', component: MenuMobileComponent },
      { path: '404', component: DashboardComponent },
      { path: '**', component: DashboardComponent },
      { path: '*', component: DashboardComponent }
    ]
  },
  //redirect if not found url - example: localhost/anything
  { path: '**', redirectTo: '', pathMatch:'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
})],
exports: [RouterModule]
})
export class AppRoutingModule { }

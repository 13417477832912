import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ObservableInput, combineLatest, forkJoin, map, merge } from 'rxjs';
import { Product } from './../models/product.model';
import { ProductDto } from './../models/productDto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) { }

  getAllProducts(): Observable<Product[]> {
    const host = environment.host;
    return this.httpClient.get<Product[]>(host + '/products', { headers: { 'http2': 'true' } });
  }

  getCustomProducts(data: any): Observable<Product[]> {
    const host = environment.host;
    return this.httpClient.get<Product[]>(host + '/products/findAllCustomData/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
  }

  getSelectedProducts(): Observable<Product[]> {
    const host = environment.host;
    /*return this.httpClient.get<Product[]>(host + '/products?selected=true');*/
    return this.httpClient.get<Product[]>(host + '/products/getSelectedProducts/', { headers: { 'http2': 'true' } });
  }

  getAvailableProducts(): Observable<Product[]> {
    const host = environment.host;
    /*return this.httpClient.get<Product[]>(host + '/products/available=true');*/
    return this.httpClient.get<Product[]>(host + '/products/getAvailableProducts/', { headers: { 'http2': 'true' } });
  }


  searchProducts(searchKeyWords: string, data: any): Observable<any> {

    const host = environment.host;
    if (searchKeyWords) {
      /*return this.httpClient.get<Product[]>(host + '/products?name_like=' + searchKeyWords);*/
      let dataBase = this.httpClient.get<Product[]>(host + '/products/searchProducts/' + searchKeyWords + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
      // let amazon = this.getSearchAmazonProducts(searchKeyWords);
      // const combine = combineLatest(dataBase, amazon).pipe(
      //   map(([a, b]) => a.concat(b))
      // );
      return dataBase;
    } else {
      return this.httpClient.get<Product[]>(host + '/products/findAllCustomData/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
    }
  }

  searchProductsByCategory(productDto: ProductDto, data: any): Observable<Product[]> {
    const host = environment.host;
    if (productDto.category && productDto.searchKeyWords) {
      return this.httpClient.get<Product[]>(host + '/products/searchProductsByCategory/' + productDto.searchKeyWords + '/' + productDto.category + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
    } else {
      return this.categoryProductsFilter(productDto.category, data);
    }
  }

  searchProductsBySource(productDto: ProductDto, data: any): Observable<Product[]> {
    const host = environment.host;
    if (productDto.source && productDto.searchKeyWords) {
      return this.httpClient.get<Product[]>(host + '/products/searchProductsBySource/' + productDto.searchKeyWords + '/' + productDto.source + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
    } else {
      return this.getSourceProductsFilter(productDto.source, data);
    }
  }

  searchProductsByPromotionCode(searchKeyWords: string, data: any): Observable<Product[]> {
    const host = environment.host;
    if (searchKeyWords) {
      return this.httpClient.get<Product[]>(host + '/products/searchProductsByPromotionCode/' + searchKeyWords + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
    } else {
      return this.getPromotionCodeProductsFilter(data);
    }
  }

  selectProduct(product: Product): Observable<Product> {
    const host = environment.host;
    product.selected = !product.selected;
    return this.httpClient.put<Product>(host + '/products/' + product._id, product, { headers: { 'http2': 'true' } });
  }

  getProduct(_id: number): Observable<Product> {
    const host = environment.host;

    return this.httpClient.get<Product>(host + '/products/' + _id, { headers: { 'http2': 'true' } });
  }

  updateProduct(product: Product): Observable<Product> {
    const host = environment.host;
    return this.httpClient.put<Product>(host + '/products/fromFrontOffice/' + product._id, product);
  }


  categoryProductsFilter(category: string, data: any): Observable<Product[]> {
    const host = environment.host;
    return this.httpClient.get<Product[]>(host + '/products/categoryProductsFilter/' + category + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
  }

  getSourceProductsFilter(source: string, data: any): Observable<Product[]> {
    const host = environment.host;
    return this.httpClient.get<Product[]>(host + '/products/getSourceProductsFilter/' + source + '/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
  }



  getPromotionCodeProductsFilter(data: any): Observable<Product[]> {
    const host = environment.host;
    return this.httpClient.get<Product[]>(host + '/products/getPromotionCodeProductsFilter/' + data.limit + '/' + data.skip, { headers: { 'http2': 'true' } });
  }

  getSearchAmazonProducts(searchKeyWords: string): Observable<Product[]> {
    const host = environment.host;
    if (searchKeyWords) {
      return this.httpClient.get<Product[]>(host + '/products/getSearchAmazonProducts/' + searchKeyWords);
    } else
      return null as any;
  }
}

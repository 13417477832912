import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SourceService } from '../../services/source.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  DeleteSourceActionError,
  DeleteSourceActionSuccess,
  GetAllSourcesActionError,
  GetAllSourcesActionSuccess,
  NewSourceActionSuccess,
  NewSourceActionError,
  SourceActions,
  SourceActionsTypesNgrx,
  SearchSourcesActionError,
  SearchSourcesActionSuccess,
  SaveSourceActionSuccess,
  SaveSourceActionError,
  EditSourceActionSuccess,
  EditSourceActionError,
  UpdateSourceActionError,
  UpdateSourceActionSuccess,
  GetSourceByNameActionError,
  GetSourceByNameActionSuccess,
} from './sources.action';


@Injectable()
export class SourcesEffects {
  constructor(
    private effectAction: Actions,
    private sourceService: SourceService
  ) { }



    /*Get SOURCE BY NAME ACTIONS*/
    getSourceByNamesSuccessEffect: Observable<SourceActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(SourceActionsTypesNgrx.GET_SOURCE_BY_NAME),
      mergeMap((action: SourceActions) => {
        return this.sourceService
          .getSourceByName(action.payload)
          .pipe(map((source) => new  GetSourceByNameActionSuccess(source)),
            catchError((err) => of(new GetSourceByNameActionError(err)))
          );
      })
    )
  );

}

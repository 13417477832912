<mat-accordion>
  <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Categories</b>
      </mat-panel-title>
    </mat-expansion-panel-header>


    <a class="dropdown-item mat-cell"
      (click)="onFilterCategory('electro')"><mat-icon>desktop_windows</mat-icon>High-tech</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell"
      (click)="onFilterCategory('videoGame')"><mat-icon>videogame_asset</mat-icon>Consoles et Jeux videos</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('fashion')"><mat-icon> local_mall</mat-icon>Mode &
      Accessoires</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('cosmetic')"><mat-icon>spa</mat-icon>Cosmétique & Bien
      être</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('sport')"><mat-icon>fitness_center</mat-icon>Sport</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('family')"><mat-icon>child_friendly</mat-icon>Enfant &
      Bébé</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('home')"><mat-icon>event_seat</mat-icon>Maison</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('animal')"><mat-icon>
        pets</mat-icon>Pets</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell"
      (click)="onFilterCategory('trip')"><mat-icon>airplanemode_active</mat-icon>Voyages</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('mechanic')"><mat-icon>
        directions_car</mat-icon>Auto Moto</a>
    <hr class="dropdown-divider">
    <!-- <a class="dropdown-item mat-cell" (click)="onFilterCategory('gardenDIY')"><mat-icon>build</mat-icon>Jardin & Bricolage</a>
    <hr class="dropdown-divider"> -->
    <!-- <a class="dropdown-item mat-cell" (click)="onFilterCategory('service')"><mat-icon>room_service</mat-icon>Services</a>
    <hr class="dropdown-divider"> -->
    <!-- <a class="dropdown-item mat-cell" (click)="onFilterCategory('featuredBrand')"><mat-icon> local_offer</mat-icon>Vedette Brands</a>
    <hr class="dropdown-divider"> -->
    <!-- <a class="dropdown-item mat-cell" (click)="onFilterCategory('training')"><mat-icon>school</mat-icon>Cultures &
      formations</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('deal')"><mat-icon>settings_phone</mat-icon>Forfaits</a>
    <hr class="dropdown-divider"> -->
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('finance')"><mat-icon>account_balance</mat-icon>Banques,
      Finances & Assurances</a>
    <!-- <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('grocery')"><mat-icon>add_shopping_cart</mat-icon>Courses & Épicerie</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('crypto')"><mat-icon>trending_up</mat-icon>Crypto</a>
    <hr class="dropdown-divider">
    <a class="dropdown-item mat-cell" (click)="onFilterCategory('free')"><mat-icon>card_giftcard</mat-icon>Gratuit</a> -->

  </mat-expansion-panel>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Rejoignez nous</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <a href="/" href="https://www.facebook.com/PromodeepCom" target="_blank" rel="noopener noreferrer"
        aria-label="facebook">
        <p class="pi pi-facebook"></p>
        <span> Facebook</span>
      </a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a href="/" href="https://www.instagram.com/promodeepcom" target="_blank" rel="noopener noreferrer"
        aria-label="instagram">
        <p class="pi pi-instagram"></p>
        <span> Instagram</span>
      </a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a href="https://twitter.com/PromodeepCom" target="_blank" rel="noopener noreferrer" aria-label="twitter">
        <p class="pi pi-twitter"></p>
        <span> Twitter</span>
      </a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a href="https://www.youtube.com/channel/UCCa1NbA_FNZxT14zPUwbmGg" target="_blank" rel="noopener noreferrer"
        aria-label="youtube">
        <p class="pi pi-youtube"></p>
        <span> Youtube</span>
      </a>
    </div>
    <!-- <hr class="dropdown-divider"> -->
    <div>
      <!-- <a href="https://www.instagram.com/promodeepcom" target="_blank" rel="noopener noreferrer" aria-label="instagram">
              <p class="pi pi-whatsapp"></p>
                      <span> Whatsapp</span>
            </a> -->
    </div>
    <div>
      <!-- <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="linkedin">
        <p class="pi pi-linkedin"></p>
                <span> Linkedin</span>
      </a> -->
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Nos applications</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <a href="https://play.google.com/store/apps/details?id=com.promodeep.twa" target="_blank"
        rel="noopener noreferrer" aria-label="android">
        <p class="pi pi-android"></p>
        <span> Android</span>
      </a>
    </div>
    <div>
      <a href="https://apps.apple.com/app/promodeep/id6448401465" target="_blank" rel="noopener noreferrer"
        aria-label="IOS">
        <p class="pi pi-apple"></p>
        <span> IOS</span>
      </a>
    </div>


  </mat-expansion-panel>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Legal</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <a class="a-padding" routerLink="/legalNotice">Mentions légales</a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a class="a-padding" routerLink="/privacyPolicy">Politique de confidentialité</a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a class="a-padding" routerLink="/cookies">Politique d'utilisation des cookies</a>
    </div>
    <hr class="dropdown-divider">
    <div>
      <a class="a-padding" routerLink="/generalPolicy">Conditions générales d'utilisations</a>
    </div>
  </mat-expansion-panel>
</mat-accordion>



<header>
  <app-header-sticky-front-office #comp2Context></app-header-sticky-front-office>
</header>

<section >
<mat-card>

      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="col">
      <!--<iframe src="https://get.cryptobrowser.site/pb/4/29246707/?t=simple,text,pro,mobile" style="width: 300px; height: 250px" frameborder="no"></iframe> -->
      </div>
      <!-- <button (click)="myElement.scrollIntoView({ behavior: 'smooth', block: 'start' })">
        Click to scroll
      </button> -->

          <router-outlet></router-outlet>

      <!-- <div class="col">
        <iframe src="https://get.cryptobrowser.site/pb/4/29246707/?t=simple,text,pro,mobile" style="width: 300px; height: 250px" frameborder="no"></iframe>
      </div> -->

</mat-card>
  </section>

<!--  <footer>
    <div style="background-color: #292c2f;">
      <br>
      <app-footer app-footer></app-footer>
    </div>
</footer>-->

<!-- <button (click)="myTopElement.scrollIntoView({ behavior: 'smooth', block: 'start' })">
  Click to scroll to top element
</button> -->

<footer *ngIf="isDesktopDevice" style="background-color: #292c2f;">
  <div>
    <br>
    <app-footer app-footer></app-footer>
  </div>
</footer>
<app-cookie-dialog ></app-cookie-dialog>
<app-nav-bar-footer-stiky  *ngIf="isDesktopDevice"></app-nav-bar-footer-stiky>
<app-nav-bar-footer-mobile  *ngIf="isMobile || isTablet" [headerStickyFrontOfficeComponent]="comp2Context"></app-nav-bar-footer-mobile>

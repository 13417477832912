import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';

@Component({
  selector: 'app-general-policy',
  templateUrl: './general-policy.component.html',
  styleUrls: ['./general-policy.component.scss']
})
export class GeneralPolicyComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle("Conditions générales d'utilisation - promodeep.com");
    this.metaTagService.updateTag (
      { name:"description", content:"➤ Conditions générales d'utilisation - promodeep.com"},
    );
  }

}

<nav *ngIf="isMobile || isTablet" class="navbar navbar-mobile ">

  <a *ngIf="!isDisplaySearch" class="navbar-brand" href="#">
    <div class="branding logo-mobile">
      <img [src]="logo" width="50" height="50" [alt]="logo" />
      <a class="a-logo" href="#" (click)="onGoDashboard()"> Promo<span class="span-logo">Deep</span></a>
    </div>
  </a>
  <form *ngIf="isDisplaySearch" class="form-contents" #f="ngForm" (ngSubmit)="onSearch(f.value)">
    <!-- <input  class="form-control rounded-pill" type="search" placeholder="Recherche" aria-label="Search" ngModel
      name='searchKeyWords' [(ngModel)]="search"> -->

    <input #stateInput (keyup)="0" (input)="onSearchAutoComplete(f.value)" [matAutocomplete]="auto"
      class="form-control input-mat-input-element" type="search" placeholder="Recherche" aria-label="Search" ngModel
      name='searchKeyWords' [(ngModel)]="search">
    <mat-autocomplete #auto="matAutocomplete">
      <div *ngIf="searchProducts">
        <mat-option class="option-autocomplete" (onSelectionChange)="stateInput.value !=undefined"
          *ngFor="let searchProductDto  of searchProducts" [value]="searchProductDto.searchProduct">
          <img width="32" *ngIf="searchProductDto.pictureUrl" src={{searchProductDto.pictureUrl}} class="logo">
          <span>{{ searchProductDto.searchProduct }}</span>
        </mat-option>
      </div>
    </mat-autocomplete>

  </form>
</nav>
<nav *ngIf="isDesktopDevice" class="navbar navbar-expand-lg navbar-light ">
  <div class="container">
    <div class="container-fluid">

      <a class="navbar-brand" href="#">
        <div class="branding logo-desktop">
          <img [src]="logo" width="50" height="50" [alt]="logo" />
          <a class="a-logo" href="#" (click)="onGoDashboard()"> Promo<span class="span-logo">Deep</span></a>
        </div>
      </a>

      <div class="collapse navbar-collapse" style="display: inline-table;" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 align-items-center">

          <li class="nav-item flex-auto">
            <form class="d-flex" #f="ngForm" (ngSubmit)="onSearch(f.value)">
              <!-- <input  class="form-control rounded-pill" type="search" placeholder="Recherche" aria-label="Search" ngModel
                name='searchKeyWords' [(ngModel)]="search"> -->
              <input #stateInput (keyup)="0" (input)="onSearchAutoComplete(f.value)" [matAutocomplete]="auto"
                class="form-control" type="search" placeholder="Recherche" aria-label="Search" ngModel
                name='searchKeyWords' [(ngModel)]="search" (focus)="onClickToSearch()" (blur)="onClickToSearch()">
              <mat-autocomplete #auto="matAutocomplete">
                <div *ngIf="searchProducts">
                  <mat-option class="option-autocomplete" (onSelectionChange)="stateInput.value !=undefined"
                    *ngFor="let searchProductDto  of searchProducts" [value]="searchProductDto.searchProduct">
                    <img *ngIf="searchProductDto.pictureUrl" src={{searchProductDto.pictureUrl}} class="logo">
                    <span>{{ searchProductDto.searchProduct }}</span>
                  </mat-option>
                </div>
              </mat-autocomplete>

            </form>
          </li>
          <!-- <li>
            <span *ngIf="cookiesNewsletter =='2'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'">
              <mat-icon matBadge="!" matBadgeColor="primary">email</mat-icon>
            </span>
            <span *ngIf="cookiesNewsletter ==='1'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'">
              <mat-icon>email</mat-icon>
            </span>
            <span *ngIf="cookiesNewsletter ==='0'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'">
              <mat-icon>email</mat-icon>
            </span>
          </li> -->
          <li>
            <span class="mat-pseudo-checkbox-disabled" *ngIf="!darkTheme" (click)="selectDarkTheme()"
              [routerLinkActive]="'activeItem'">
              <mat-icon>brightness_2</mat-icon>
            </span>

            <span class="mat-pseudo-checkbox-disabled" *ngIf="darkTheme" (click)="selectLightTheme()"
              [routerLinkActive]="'activeItem'">
              <mat-icon>wb_sunny</mat-icon>
            </span>
          </li>
          <!-- <li *ngIf="!mobile" class="nav-item">
            <a class="nav-link" routerLink="" style="color: rgb(255, 255, 255);"
              (click)="onFilterByPromotionCode()">Codes Promo</a>
          </li> -->

        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="container">
  <nav mat-button-toggle-button *ngIf="isDesktopDevice" mat-tab-nav-bar class="ms-nav-list-scroll"
    disablePagination="false" (selectedTabChange)="tabChanged($event)" mat-align-tabs="center"
    backgroundColor="backgroundThemeColor">

    <a routerLink="/" (click)="onDashboard()" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>notifications_active</mat-icon>
      PROMOTIONS
    </a>

    <a routerLink="/" style="color: rgb(255, 255, 255);" (click)="onFilterByPromotionCode()"
      [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>loyalty</mat-icon> CODES PROMO
    </a>

    <a style="color: rgb(255, 255, 255);" (click)="diplayNavCategories()" [routerLinkActive]="'activeItem'"
      id="navbarDropdown" mat-tab-link>
      <mat-icon>filter_list</mat-icon>
      Categories
    </a>
  </nav>


</div>


<div class="container">
  <nav mat-button-toggle-button *ngIf="isDesktopDevice && boolnavCategories" mat-tab-nav-bar disablePagination="false"
    (selectedTabChange)="tabChanged($event)" mat-align-tabs="center">



    <a routerLink="/" (click)="onFilterCategory('electro')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>desktop_windows</mat-icon>
      <span>High-Tech</span>
    </a>


    <a routerLink="/" (click)="onFilterCategory('videoGame')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>videogame_asset</mat-icon>
      Consoles et Jeux videos
    </a>


    <a routerLink="/" (click)="onFilterCategory('fashion')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon> local_mall</mat-icon>
      Mode & Accessoires
    </a>


    <a routerLink="/" (click)="onFilterCategory('cosmetic')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>spa</mat-icon>
      Cosmétique & Bien être
    </a>


    <a routerLink="/" (click)="onFilterCategory('sport')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>fitness_center</mat-icon>
      Sport
    </a>


    <a routerLink="/" (click)="onFilterCategory('family')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>child_friendly</mat-icon>
      Enfant & Bébé
    </a>


    <a routerLink="/" (click)="onFilterCategory('home')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>event_seat</mat-icon>
      Maison
    </a>

    <a routerLink="/" (click)="onFilterCategory('animal')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>pets</mat-icon>
      Pets
    </a>


    <a routerLink="/" (click)="onFilterCategory('trip')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>airplanemode_active</mat-icon>
      Voyages
    </a>

    <a routerLink="/" (click)="onFilterCategory('mechanic')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon> directions_car</mat-icon>
      Auto Moto
    </a>

    <!--  <a routerLink="/" (click)="onFilterCategory('training')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>school</mat-icon>
      Cultures & formations
    </a> -->


    <!-- <a routerLink="/" (click)="onFilterCategory('deal')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>settings_phone</mat-icon>
      Forfaits
    </a> -->


    <a routerLink="/" (click)="onFilterCategory('finance')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>account_balance</mat-icon>
      Banques, Finances & Assurances
    </a>


    <!-- <a routerLink="/" (click)="onFilterCategory('featuredBrand')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon> local_offer</mat-icon>
      Vedette Brands
    </a> -->


    <!-- <a routerLink="/" (click)="onFilterCategory('gardenDIY')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>build</mat-icon>
      Jardin & Bricolage
    </a> -->


    <!-- <a routerLink="/" (click)="onFilterCategory('service')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>room_service</mat-icon>
      Services
    </a> -->








    <!-- <a routerLink="/" (click)="onFilterCategory('grocery')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>add_shopping_cart</mat-icon>
      Courses & Épicerie
    </a>


    <a routerLink="/" (click)="onFilterCategory('crypto')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>trending_up</mat-icon>
      Crypto
    </a>


    <a routerLink="/" (click)="onFilterCategory('free')" [routerLinkActive]="'activeItem'" mat-tab-link>
      <mat-icon>card_giftcard</mat-icon>
      Gratuit
    </a> -->
  </nav>


</div>
<nav *ngIf="!isDesktopDevice" mat-tab-nav-bar class="ms-nav-list-scroll" disablePagination="true"
  (selectedTabChange)="tabChanged($event)" mat-align-tabs="center" backgroundColor="backgroundThemeColor">

  <a routerLink="/" (click)="onDashboard()" [routerLinkActive]="'activeItem'" mat-tab-link>
    <mat-icon>notifications_active</mat-icon>
    Promotions
  </a>


  <a routerLink="/" (click)="onFilterByPromotionCode()" [routerLinkActive]="'activeItem'" mat-tab-link>
    <mat-icon>loyalty</mat-icon>
    Codes Promo
  </a>
  <a style="color: rgb(255, 255, 255);" (click)="OpenMenuMobile()" [routerLinkActive]="'activeItem'" id="navbarDropdown"
    mat-tab-link>
    <mat-icon>filter_list</mat-icon>
    Categories
  </a>
</nav>

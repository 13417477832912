import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Contact } from 'src/app/models/contact';
import { CanonicalService } from 'src/app/services/canonical.service';
import { emailValidator } from 'src/app/shared/email-validator.directive';
import { contactTypeEnum } from 'src/assets/constantEnum';
import { ContactService } from '../../../services/contact.service';
import { SettingService } from '../../../services/setting.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactFormGroup!: UntypedFormGroup;
  contactTypes!: Array<string>;
  settings!: any;
  setting!: any;
  contact = {} as Contact
  successSend: boolean = false;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private contactService: ContactService,
    private settingService: SettingService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle("Contactez-nous - promodeep.com");
    this.metaTagService.updateTag (
      { name:"description", content:"➤ N'hésitez pas à prendre contact avec le service client de promodeep si vous avez besoin d'un service, d'information complémentaire - promodeep.com"},
    );
    this.contactFormGroup =
    this.formBuilder.group({

      subject: new UntypedFormControl(this.contact.subject, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      pseudo: new UntypedFormControl(this.contact.pseudo, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),

      emailContact: new UntypedFormControl(this.contact.emailContact, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),

      messageReceived: new UntypedFormControl(this.contact.messageReceived, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2500),
      ]),
      dateReceived: [new Date(), Validators.required],
      emailRepliedFlag: [false, Validators.required],
  });

  this.contactTypes = Object.keys(contactTypeEnum).filter((key) => isNaN(+key));

  this.settingService.getAllSettings().subscribe(settings => {
    this.settings = settings;
    this.setting = this.settings[0];
  });
}



get pseudo() {
  return this.contactFormGroup.get('pseudo')!;
}

get subject() {
  return this.contactFormGroup.get('subject')!;
}

get emailContact() {
  return this.contactFormGroup.get('emailContact')!;
}

get messageReceived() {
  return this.contactFormGroup.get('messageReceived')!;
}


get f() { return this.contactFormGroup.controls; }

  onSendContact(){
    if (this.contactFormGroup.invalid) {
      return;
    }
    this.submitted = true;
    this.contactFormGroup.addControl('emailReply', new UntypedFormControl(this.setting.email));

        this.contactService.save(this.contactFormGroup?.value).subscribe();

    this.successSend = true;

  }


}

import { Action } from '@ngrx/store';
import { Newsletter } from '../../models/newsletter';
import { NewsletterActions, NewsletterActionsTypesNgrx } from './newsletter.action';

export  const FeatureStateName = 'NewslettersStateFromModule';

export enum NewslettersStateEnum {
  LOADING= 'LOADING',
  LOADED= 'LOADED',
  ERROR= 'ERROR',
  INITIAL= 'INITIAL',
  NEW= 'NEW',
  EDIT= 'EDIT',
  UPDATED= 'UPDATED'
}

export interface NewslettersState {

  newsletters: Array<Newsletter>;
  selectNewsletter: Newsletter | null;
  errorMessage: string;
  dataStateNgrx: NewslettersStateEnum;
  currentNewsletter: Newsletter|null;
  currentAction: NewsletterActions|null;
}

const initState: NewslettersState = {
  newsletters: [],
  selectNewsletter: null,
  errorMessage: '',
  dataStateNgrx: NewslettersStateEnum.INITIAL,
  currentNewsletter: null,
  currentAction:null

};

export function newslettersReducer (state: NewslettersState= initState, action: Action): NewslettersState {

    switch (action.type) {

    /*Get NEWSLETTER*/
    case NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER_SUCCESS:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, newsletters: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.GET_ALL_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get SEARCH NEWSLETTER*/
    case NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING};
    case NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER_SUCCESS:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, newsletters: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.SEARCH_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get NEWSLETTER BY NAME*/
    case NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING};
    case NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME_SUCCESS:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, currentNewsletter: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.GET_NEWSLETTER_BY_NAME_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get NEW NEWSLETTER*/
    case NewsletterActionsTypesNgrx.NEW_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.NEW_NEWSLETTER_SUCCESS:
    return {...state, dataStateNgrx : NewslettersStateEnum.NEW, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.NEW_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get SAVE NEWSLETTER*/
    case NewsletterActionsTypesNgrx.SAVE_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.SAVE_NEWSLETTER_SUCCESS:
      const prods: Newsletter[] = [...state.newsletters];
      prods.push((<NewsletterActions>action).payload);
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, newsletters: prods, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.SAVE_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get EDIT NEWSLETTER*/
    case NewsletterActionsTypesNgrx.EDIT_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.EDIT_NEWSLETTER_SUCCESS:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, currentNewsletter: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.EDIT_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    /*Get UPDATE NEWSLETTER*/
    case NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER_SUCCESS:
    const newsletterUpdated: Newsletter = (<NewsletterActions>action).payload;
    const newsletterListUpdatedt = state.newsletters.map(p => (p._id === newsletterUpdated._id) ? newsletterUpdated : p);
    return {...state, dataStateNgrx : NewslettersStateEnum.UPDATED, newsletters: newsletterListUpdatedt, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.UPDATE_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};


    /*Get DELETE NEWSLETTER*/
    case NewsletterActionsTypesNgrx.DELETE_NEWSLETTER:
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADING, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.DELETE_NEWSLETTER_SUCCESS:
    const newsletterDelete: Newsletter = (<NewsletterActions>action).payload;
    const index = state.newsletters.indexOf(newsletterDelete);
    const list = [...state.newsletters];
    list.splice(index, 1);
    const dataNewsletter: Newsletter[] = list.map(p => p._id === newsletterDelete._id ? newsletterDelete : p);
    return {...state, dataStateNgrx : NewslettersStateEnum.LOADED, newsletters: dataNewsletter, currentAction: <NewsletterActions>action};
    case NewsletterActionsTypesNgrx.DELETE_NEWSLETTER_ERROR:
    return {...state, dataStateNgrx : NewslettersStateEnum.ERROR, errorMessage: (<NewsletterActions>action).payload, currentAction: <NewsletterActions>action};

    default : return {...state};
  }
}

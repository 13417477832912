import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContactService } from '../../services/contact.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  DeleteContactActionError,
  DeleteContactActionSuccess,
  GetAllContactsActionError,
  GetAllContactsActionSuccess,
  NewContactActionSuccess,
  NewContactActionError,
  ContactActions,
  ContactActionsTypesNgrx,
  SearchContactsActionError,
  SearchContactsActionSuccess,
  SaveContactActionSuccess,
  SaveContactActionError,
  EditContactActionSuccess,
  EditContactActionError,
  UpdateContactActionError,
  UpdateContactActionSuccess,
  GetContactByNameActionError,
  GetContactByNameActionSuccess,
} from './contacts.action';


@Injectable()
export class ContactsEffects {
  constructor(
    private effectAction: Actions,
    private contactService: ContactService
  ) { }


  /*Get NEW CONTACT ACTIONS*/
  // NewContactsSuccessEffect: Observable<ContactActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(ContactActionsTypesNgrx.NEW_CONTACT),
  //     map((action: ContactActions) => {
  //       return new NewContactActionSuccess({});
  //     })
  //   )
  // );

  /*Get SAVE CONTACT ACTIONS*/
  SaveContactsSuccessEffect: Observable<ContactActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ContactActionsTypesNgrx.SAVE_CONTACT),
      mergeMap((action: ContactActions) => {
        return this.contactService
          .save(action.payload)
          .pipe(map((contact) => new SaveContactActionSuccess(contact)),
            catchError((err) => of(new SaveContactActionError(err)))

          );

      })
    )
  );
}

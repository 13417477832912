import { Product } from "../models/product.model";

export class TimeCalculator {
  dealBloced!: boolean;

  calculateDiffAddDate(dateSent: Date) {
    var calculateDiffEndDate = "";
    if (dateSent) {
      let currentDate = new Date();
      dateSent = new Date(dateSent);
      var diffMs = currentDate.getTime() - dateSent.getTime();
      var diffDays = Math.floor(diffMs / 86400000); // days
      if (diffDays > 1) {
        //calculateDiffEndDate = calculateDiffEndDate + diffDays + " j, "
        calculateDiffEndDate = " " + dateSent.getDate() + " " + this.monthNames[dateSent.getMonth()]
        if (dateSent.getFullYear() < currentDate.getFullYear()) {
          calculateDiffEndDate = calculateDiffEndDate + " " + dateSent.getFullYear();
        }

      } else {
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        //calculateDiffEndDate = calculateDiffEndDate + diffDays + " j, "
        if (diffHrs > 0) {
          calculateDiffEndDate = calculateDiffEndDate + diffHrs + " h "
        }
        if (diffMins >= 0) {
          calculateDiffEndDate = calculateDiffEndDate + diffMins + " m"
        }
        if (calculateDiffEndDate) {
          calculateDiffEndDate = "  " + calculateDiffEndDate;
        }

      }
    }
    return calculateDiffEndDate;
  }

  monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juiller", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
  ];

  calculateDiffEndDate(product: Product) {
    this.dealBloced = false;
    var calculateDiffEndDate = "";
    if (product.expirationDate) {
      let currentDate = new Date();
      let dateSent = new Date(product.expirationDate);
      var diffMs = dateSent.getTime() - currentDate.getTime();
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      // if (diffMs > 0) {
      //   if (diffDays > 0) {
      //     calculateDiffEndDate = calculateDiffEndDate + diffDays + " j "
      //   }
      //   if (diffDays = 0) {
      //     if (diffHrs > 0) {
      //       calculateDiffEndDate = calculateDiffEndDate + diffHrs + " h "
      //     }
      //     if (diffMins > 0) {
      //       calculateDiffEndDate = calculateDiffEndDate + diffMins + " min "
      //     }

      //   }
      //   if (calculateDiffEndDate) {
      //     //calculateDiffEndDate = "Expire dans " + calculateDiffEndDate;
      //     this.dealBloced = false;
      //   }

      // }
      if (diffDays == -1) {
        this.dealBloced = true;
        calculateDiffEndDate = "Expire Bientôt"
      }

      if (diffDays >= 0) {
        this.dealBloced = false;
      }

      if (diffDays < -1) {
        this.dealBloced = true;
        calculateDiffEndDate = "Expiré"
        // calculateDiffEndDate = calculateDiffEndDate + " " + dateSent.getDate() + " " + this.monthNames[dateSent.getMonth()]
        // if (dateSent.getFullYear() < currentDate.getFullYear()) {
        //   calculateDiffEndDate = calculateDiffEndDate + " " + dateSent.getFullYear();
        // }
      }
    }


    return calculateDiffEndDate;
  }

}

<mat-card>
<div class="container">
  <div>
    <div class="fixed-bottom mat-checkbox-mixedmark w-100">
      <!-- <div class="text-start p-2 border-top d-flex flex-column">
            <div class="example-button-row">
              <div class="example-flex-container" style="display: flex;">
                <strong class="me-auto"></strong>
                <button mat-icon-button (click)="onScrollToHeader()" aria-label="Example icon button with a home icon">
                  <mat-icon>home</mat-icon>
                </button>

                <strong class="me-auto"></strong>
                <button mat-icon-button (click)="onDisplaySearch()" aria-label="Example icon button with a heart icon">
                  <mat-icon>search</mat-icon>
                </button>
                <strong class="me-auto"></strong>
                <button mat-icon-button (click)="OpenMenuMobile()" aria-label="Example icon button with a menu icon">
                  <mat-icon>menu</mat-icon>
                </button>
                <strong class="me-auto"></strong>
              </div>
        </div>
      </div> -->

      <nav mat-tab-nav-bar mat-stretch-tabs [ngStyle]="{'height': isIOS ? '65px' : '' }"
      style="height: 200px;" color="primary">
        <span  (click)="onScrollToHeader()" routerLink="/" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>home</mat-icon>
        </span>


        <span  (click)="onDisplaySearch()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>search</mat-icon>
        </span>

        <span *ngIf="!darkTheme" (click)="selectDarkTheme()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>brightness_2</mat-icon>
        </span>

        <span *ngIf="darkTheme" (click)="selectLightTheme()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>wb_sunny</mat-icon>
        </span>


        <!-- <span *ngIf="cookiesNewsletter =='2'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon matBadge="!" matBadgeColor="primary">email</mat-icon>
        </span>
        <span *ngIf="cookiesNewsletter ==='1'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>email</mat-icon>
        </span>
        <span *ngIf="cookiesNewsletter ==='0'" class="mat-pseudo-checkbox-disabled"  (click)="openDialogNewsLetter()" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>email</mat-icon>
        </span> -->


        <span (click)="OpenMenuMobile()" routerLink="/" [routerLinkActive]="'activeItem'" mat-tab-link>
          <mat-icon>menu</mat-icon>
        </span>


      </nav>
    </div>
  </div>
</div>
</mat-card>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotion-code',
  templateUrl: './promotion-code.component.html',
  styleUrls: ['./promotion-code.component.scss']
})
export class PromotionCodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}

<div class="container">
  <div>
    <div class="fixed-bottom w-100 bg-white" *ngIf="!this.closePopup" (hidden)="deny()">
      <div class="text-start p-4 flex-column height-cookies">
        <div class="text-end">
          <span class="veryLargeText">Cookies</span>
          <strong class="me-auto"></strong>
          <a mat-button class="verySmallText" (click)="deny()" routerLink="/">
            Continuer sans accepter >
          </a>
        </div>
        <br>
        <p>
          Nous utilisons des cookies pour vous offrir une expérience Web optimale. En poursuivant votre navigation sur
          le site, vous acceptez l'utilisation de cookies.
          Vous pouvez en savoir plus sur les cookies que nous utilisons en cliquant sur le lien: <a class="verySmallText" routerLink="privacyPolicy">Politique de
            confidentialité.</a>
        </p>
        <div class="text-end">
          <button mat-stroked-button type="button" (click)="accept()" class="btn btn position-relative accept-button" id="btnAccept">
            Accepter
          </button>
          <button mat-stroked-button type="button" (click)="openDialog()" class="btn btn position-relative param-button " id="btnDeny">
            Préférences
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


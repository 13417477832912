<nav id="sidenav-content" class="navbar navbar-expand-lg navbar-light ">
  <div class="container">
    <div class="container-fluid">
      <span class="nav-link" style="color: rgb(255, 255, 255);" (click)="onScrollToHeader()"> Haut de page</span>
      <span *ngIf="!mobile" href="#">
        <div class="branding">
          <img [src]="logo" width="40" height="40" [alt]="logo" />
        </div>
      </span>
      <span class="nav-link" *ngIf="visibleSidebar4 === false" style="color: rgb(255, 255, 255);" (click)="onScrollToFooter()"> Afficher le footer</span>
      <span class="nav-link" *ngIf="visibleSidebar4" style="color: rgb(255, 255, 255);" (click)="onHideToFooter()"> Masquer le footer</span>
    </div>
  </div>
</nav>


<!-- <p-sidebar [(visible)]="visibleSidebar4" styleClass="p-sidebar-lg" position="bottom" [baseZIndex]="0" [autoZIndex]="false" [modal]="true">
  <app-footer (click)="visibleSidebar4 = false" app-footer></app-footer>
</p-sidebar> -->

<mat-nav-list *ngIf="visibleSidebar4">
  <!-- <app-footer (click)="visibleSidebar4 = false"></app-footer> -->
  <app-footer></app-footer>
</mat-nav-list>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TypeProduct } from '../models/type-product';

@Injectable({
  providedIn: 'root'
})
export class TypeProductService {

  constructor(private httpClient: HttpClient) { }

  searchTypeProduct(searchKeyWords: string): Observable<TypeProduct[]> {
    const host = environment.host;
      /*return this.httpClient.get<Product[]>(host + '/products?name_like=' + searchKeyWords);*/
      return this.httpClient.get<TypeProduct[]>(host + '/typeProducts/searchTypeProduct/' + searchKeyWords);

  }
}

import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {

  constructor(private rendererFactory: RendererFactory2, @Inject (DOCUMENT) private dom: any) { }

  setCanonicalURL (url?: string) {
    this.destroyLinkForCanonicalURL();
    // var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    const canURL = url == undefined ? this.dom.URL : url;
    const link : HTMLLinkElement = this.dom.createElement('link');
    link.removeAttribute('rel');
    link.removeAttribute('href');
    link.setAttribute('rel','canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }


  destroyLinkForCanonicalURL() {
    const els = this.dom.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  addTag(tag: LinkDefinition, url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    //const link : HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel','Canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', canURL);
    try {
        const renderer = this.rendererFactory.createRenderer(this.dom, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        const link = renderer.createElement('link');
        const head = this.dom.head;
        //const selector = this._parseSelector(tag);

        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }

        Object.keys(tag).forEach((prop: string) => {
            return renderer.setAttribute(link, prop, tag[prop]);
        });

        // [TODO]: get them to update the existing one (if it exists) ?
        renderer.appendChild(head, canURL);

    } catch (e) {
        console.error('Error within linkService : ', e);
    }
}

// private _parseSelector(tag: LinkDefinition): string {
//     // Possibly re-work this
//     const attr: string = tag.rel ? 'rel' : 'hreflang';
//     return `${attr}="${tag[attr]}"`;
// }
}

 export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
    [prop: string]: string;
};

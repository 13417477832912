<div  class="dialog-letter">
<h1 mat-dialog-title>Inscription à la newsletter</h1>
<mat-dialog-content class="mat-typography">
  <h3>
    Pour recevoir une newsletter PromoDeep et ne manquer aucune offre, renseignez votre adresse e-mail.
  </h3>

  <form [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="exemple@exemple.com" formControlName="email" />
      <!-- <mat-label>Email:</mat-label>
      <input type="email" matInput required [(ngModel)]="data.email" minlength="1" maxlength="250" class="form-control"
        placeholder="exemple@exemple.com"> -->

    </mat-form-field>

      <mat-slide-toggle><span>J'accepte de recevoir la newsletter de promodeep.com</span></mat-slide-toggle>
      <mat-slide-toggle><span>J'accepte de recevoir des publicités de nos partenaires</span></mat-slide-toggle>

    <!-- <div *ngIf="data.email.invalid && (data.email.email.dirty || data.email.touched)" class="invalid-feedback">
      <div *ngIf="data.email.errors?.['required']">
        This field is required.
      </div>
      <div *ngIf="data.email.errors?.['minlength']">
        This field must have at least 1 character.
      </div>
      <div *ngIf="data.email.errors?.['maxlength']">
        This field must have at most 250 characters.
      </div>
      <div *ngIf="!data.email.errors?.['required'] && !data.email.errors?.['minlength'] && !data.email.errors?.['maxlength'] && data.email.errors?.['emailValidator']">
        Invalid email format.
      </div>
    </div> -->
    <br>
    <br>
    <p>Vous affirmez pris connaissance  de notre <a class="verySmallText" routerLink="privacyPolicy">Politique de
      confidentialité.</a></p>
    <br>
    <h5>
      Si vous ne souhaitez plus recevoir d'email newsletter cliquez <a routerLink="/"
        [mat-dialog-close]="false" (click)="openDialogNewsLetterDisable()">Ici</a>.
      <!-- ou via les liens de désinscription présents dans chacun de nos mails. -->
    </h5>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false" mat-dialog-close>Fermer</button>
  <button type="submit" [disabled]="form.invalid" (click)="acceptNewsLetter= true;submit()"
    [mat-dialog-close]="data.acceptNewsLetter" cdkFocusInitial mat-raised-button> Valider </button>
</mat-dialog-actions>
</div>

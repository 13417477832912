import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HeaderStickyFrontOfficeComponent, DialogData } from '../header-sticky/header-sticky-front-office/header-sticky-front-office.component';
import { FormGroup, FormBuilder, Validators, NgForm, UntypedFormControl } from '@angular/forms';
import { emailValidator } from "src/app/shared/email-validator.directive";
import { Newsletter } from "src/app/models/newsletter";
import { SaveNewsletterAction, UpdateNewsletterAction } from "src/app/ngrx/newsletter/newsletter.action";
import { Store } from "@ngrx/store";
import { NewsletterService } from "src/app/services/newsletter.service";

@Component({
  selector: 'dialog-content-newsletter-disable',
  templateUrl: 'dialog-content-newsletter-disable.html',
})
export class DialogContentNewsLetterDisable {

  form!: FormGroup;
  acceptNewsLetter: boolean = false;
  newsletter = {} as Newsletter

  constructor(public DialogRef: MatDialogRef<HeaderStickyFrontOfficeComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogContentNewsLetterDisable>,
    private newsLetterService: NewsletterService
  ) {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.newsletter.email, [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator()]),
      active: false,
      updateDate: new Date(),
    });
  }

  submit() {
    this.dialogRef.close({
      email: this.form.controls['email'].value,
    });
    this.onSaveNewsletter();
  }

  onSaveNewsletter(){
    if (this.form.invalid) {
      return;
    }
    this.newsLetterService.update(this.form?.value).subscribe();

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReferenceProduct } from '../models/reference-product';

@Injectable({
  providedIn: 'root'
})
export class ReferenceProductService {

  constructor(private httpClient: HttpClient) { }

  searchReferenceProduct(searchKeyWords: string): Observable<ReferenceProduct[]> {
    const host = environment.host;
      /*return this.httpClient.get<Product[]>(host + '/products?name_like=' + searchKeyWords);*/
      return this.httpClient.get<ReferenceProduct[]>(host + '/referenceProducts/searchReferenceProducts/' + searchKeyWords);

  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductService } from '../services/product.service';
import { Observable, of } from 'rxjs';
import {
  DeleteProductActionError,
  DeleteProductActionSuccess,
  GetAllProductsActionError,
  GetAllProductsActionSuccess,
  GetSelectedProductsActionError,
  GetSelectedProductsActionSuccess,
  NewProductActionSuccess,
  NewProductActionError,
  ProductActions,
  ProductActionsTypesNgrx,
  SearchProductsActionError,
  SearchProductsActionSuccess,
  SelectProductActionError,
  SelectProductActionSuccess,
  SaveProductActionSuccess,
  SaveProductActionError,
  EditProductActionSuccess,
  EditProductActionError,
  UpdateProductActionError,
  UpdateProductActionSuccess,
  FilterCategoryActionSuccess,
  FilterCategoryActionError,
  FilterBySourceActionSuccess,
  FilterBySourceActionError,
  FilterByPromotionCodeActionSuccess,
  FilterByPromotionCodeActionError,
  SearchProductsByCategoryActionSuccess,
  SearchProductsByCategoryActionError,
  SearchProductsBySourceActionSuccess,
  SearchProductsBySourceActionError,
  SearchProductsByPromotionCodeActionSuccess,
  SearchProductsByPromotionCodeActionError,
} from './products.action';
import { map, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class ProductsEffects {
  constructor(
    private effectAction: Actions,
    private productService: ProductService
  ) { }

  getAllProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.GET_ALL_PRODUCTS),
      mergeMap((action: ProductActions) => {
        return this.productService
          .getCustomProducts(action.payload)
          .pipe(map((products) => new GetAllProductsActionSuccess(products)),
            catchError((err) => of(new GetAllProductsActionError(err)))
          );
      })
    )
  );
  /*Get SELECTED PRODUCTS ACTIONS*/
  // getSelectedProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(ProductActionsTypesNgrx.GET_SELECTED_PRODUCTS),
  //     mergeMap((action: Actions) => {
  //       return this.productService
  //         .getSelectedProducts()
  //         .pipe(map((products) => new GetSelectedProductsActionSuccess(products)),
  //           catchError((err) => of(new GetSelectedProductsActionError(err)))
  //         );
  //     })
  //   )
  // );

  /*Get SEARCH PRODUCTS ACTIONS*/
  getSearchProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.SEARCH_PRODUCTS),
      mergeMap((action: ProductActions) => {
        return this.productService
          .searchProducts(action.payload.searchKeyWords, action.payload.data)
          .pipe(map((products) => new SearchProductsActionSuccess(products)),
            catchError((err) => of(new SearchProductsActionError(err)))
          );
      })
    )
  );

  /*Get SEARCH PRODUCTS ACTIONS*/
  getSearchProductsByCategorySuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_CATEGORY),
      mergeMap((action: ProductActions, data : any) => {
        return this.productService
          .searchProductsByCategory(action.payload, data)
          .pipe(map((products) => new SearchProductsByCategoryActionSuccess(products)),
            catchError((err) => of(new SearchProductsByCategoryActionError(err)))
          );
      })
    )
  );

  /*Get SEARCH SOURCE ACTIONS*/
  getSearchProductsBySourceSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_SOURCE),
      mergeMap((action: ProductActions, data : any) => {
        return this.productService
          .searchProductsBySource(action.payload, data)
          .pipe(map((products) => new SearchProductsBySourceActionSuccess(products)),
            catchError((err) => of(new SearchProductsBySourceActionError(err)))
          );
      })
    )
  );

  /*Get SEARCH PROMOTIONCODE ACTIONS*/
    getSearchProductsByPromotionCodeSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.SEARCH_PRODUCTS_BY_PROMOTION_CODE),
      mergeMap((action: ProductActions, data : any) => {
        return this.productService
          .searchProductsByPromotionCode(action.payload, data)
          .pipe(map((products) => new SearchProductsByPromotionCodeActionSuccess(products)),
            catchError((err) => of(new SearchProductsByPromotionCodeActionError(err)))
          );
      })
    )
  );


  // /*Get NEW PRODUCTS ACTIONS*/
  // NewProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
  //   this.effectAction.pipe(
  //     ofType(ProductActionsTypesNgrx.NEW_PRODUCTS),
  //     map((action: ProductActions) => {
  //       return new NewProductActionSuccess({});
  //     })
  //   )
  // );

  /*Get EDIT PRODUCTS ACTIONS*/
  EditProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.EDIT_PRODUCTS),
      mergeMap((action: ProductActions) => {
        return this.productService
          .getProduct(action.payload)
          .pipe(map((product) => new EditProductActionSuccess(product)),
            catchError((err) => of(new EditProductActionError(err)))

          );

      })
    )
  );

  /*Get UPDATE PRODUCTS ACTIONS*/
  UpdateProductsSuccessEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.UPDATE_PRODUCTS),
      mergeMap((action: ProductActions) => {
        return this.productService
          .updateProduct(action.payload)
          .pipe(map((product) => new UpdateProductActionSuccess(product)),
            catchError((err) => of(new UpdateProductActionError(err)))

          );

      })
    )
  );


  /*Get FILTER CATEGORY ACTIONS*/
  getFilterCategoryActionEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.FILTER_CATEGORY),
      mergeMap((action: ProductActions) => {
        return this.productService
          .categoryProductsFilter(action.payload.category, action.payload.data)
          .pipe(map((products) => new FilterCategoryActionSuccess(products)),
            catchError((err) => of(new FilterCategoryActionError(err)))
          );
      })
    )
  );

  /*Get FILTER BY SOURCE ACTIONS*/
  getFilterBySourceActionEffect: Observable<ProductActions> = createEffect(() =>
    this.effectAction.pipe(
      ofType(ProductActionsTypesNgrx.FILTER_BY_SOURCE),
      mergeMap((action: ProductActions) => {
        return this.productService
          .getSourceProductsFilter(action.payload.source, action.payload.data)
          .pipe(map((products) => new FilterBySourceActionSuccess(products)),
            catchError((err) => of(new FilterBySourceActionError(err)))
          );
      })
    )
  );

   getFilterByPromotionCodeActionEffect: Observable<ProductActions> = createEffect(() =>
   this.effectAction.pipe(
     ofType(ProductActionsTypesNgrx.FILTER_BY_PROMO_CODE),
     mergeMap((action: ProductActions) => {
       return this.productService
         .getPromotionCodeProductsFilter(action.payload)
         .pipe(map((products) => new FilterByPromotionCodeActionSuccess(products)),
         catchError((err) => of(new FilterByPromotionCodeActionError(err)))
         );
     })
   )
 );

}

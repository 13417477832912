import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
 import { SwPush, SwUpdate, UnrecoverableStateEvent, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SettingsState } from './ngrx/setting/settings.reducer';
import { NotificationService } from 'src/app/services/notifications.service';
import { ProductsState } from './ngrx/products.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  notificationData: string = '{}';
  settingSubscription!: Subscription;
  settingsState: SettingsState | null = null;
  //settingId: string;
  settings!: any;
  setting!: any;
  state: ProductsState | null = null;

  constructor(
    private updateService: SwUpdate,
    private metaTagService: Meta,
    private store: Store<any>,
    private pushService: SwPush,
    private notificationService: NotificationService,
  ) {}
  ngOnInit() {
    // this.handleUpdates();
    // this.handleNotifications();
    // this.sendNotification();
    console.log("hello 2");
    this.metaTagService.addTags ([
      { name: "robots", content:"index, follow"},
      { charset:"UTF-8"},
      { name:"description", content:"Les meilleures offres et codes promo ! Ne chercher pas loin les promotions et les réductions ici les meilleures affaires ⇒ sur promodeep.com."},
      { name:"keywords", content:"offres, codes promo, promotions, réductions, bon plan"},
      { name:"author", content:"TechnoDeep"},
      { name:"viewport", content:"width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=3.0"},
      { name:"apple-mobile-wep-app-capable", content:"yes"},
      { name:"mobile-wep-app-capable", content:"yes"},
      { name:"theme-color", content:"#1976d2"},

      // { name:"apple-itunes-app", content:"app-id=123456789"},
      // { name:"google-play-app", content:"app-id=com.promodeep.apps.android"}
      // { name:"google-site-verification", content:""},
      // { property:"prt", content:"0123456789"},
    ]);
    if (!this.updateService.isEnabled) {
      return;
    }
  }

  handleUpdates(): void {
    this.updateService.versionUpdates.subscribe((event: VersionEvent) => {
      if (
        event.type === 'VERSION_READY' &&
        confirm(
          `New version ${
            (event as VersionReadyEvent).latestVersion.hash
          } available. Load New Version?`
        )
      ) {
        window.location.reload();
      }
    });
    const interval = setInterval(async () => {
      const shouldUpdate = await this.updateService.checkForUpdate();
      //alert('Checked for update with result: ' + shouldUpdate);
      if (shouldUpdate) {
        const result = await this.updateService.activateUpdate();
        alert('Activate Update completed with result: ' + result);
        clearInterval(interval);
      }
    }, 1000);

    this.updateService.unrecoverable.subscribe(
      (event: UnrecoverableStateEvent) => {
        alert('Error reason : ' + event.reason);
      }
    );
  }

  async handleNotifications() {
    try {
      this.settingSubscription = this.store.subscribe(async myStore => {
        this.settingsState = myStore.SettingsStateFromModule;
        this.settings = this.settingsState?.settings;

        if (this.settings.length > 0) {
            this.setting = this.settings[0];
            const notificationData = {
              publicKeyNotification: this.setting.publicKeyNotification,
              privateKeyNotification: this.setting.privateKeyNotification,
            }
      const sub = await this.pushService.requestSubscription({
        serverPublicKey: this.setting.publicKeyNotification,
      });
      this.notificationService.addSubscription(sub);
    }
  });

    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
    this.pushService.messages.subscribe(() => {
    });
    this.pushService.notificationClicks.subscribe(() => {
    });
    this.pushService.subscription.subscribe();
  }

  sendNotification() {
      if (this.settings.length > 0) {
          this.setting = this.settings[0];

          const keyNotification = {
            publicKeyNotification: this.setting.publicKeyNotification,
            privateKeyNotification: this.setting.privateKeyNotification,
          }
          const notificationData = {
            notification:{
            body:"Reduction ",
            title: "fdf",
            vibrate:[300,100,400,100,400,100,400],
            icon:"assets/logo4.png",

            tag:"push demo",
            requireInteraction:true,
            renotify:true,

            data:{
              onActionClick:{
                      navigate: {
                      operation: "openWindow",
                      url:"https://promodeep.com"
                          }
                      }
                },
                actions: [
                  {action: "navigate", title: "Open new tab"}
                ]
          }
        }
          this.notificationService.notifications(JSON.stringify(notificationData), JSON.stringify(keyNotification));

          // this.settingFormGroup = this.formBuilder.group({
          //   _id: [this.setting._id, Validators.required],
          //   email: [this.setting.email, Validators.required],
          //   publicKeyNotification: [this.setting.publicKeyNotification, Validators.required],
          //   privateKeyNotification: [this.setting.privateKeyNotification, Validators.required],
            //publicKeyNotification: [this.state.currentSetting.publicKeyNotification, Validators.required],

          //});

      }
  }

}

<br>
<div class="container">
  <div *ngIf="source">
    <a href="{{source.affiliateUrl}}" target="_blank" rel="noopener noreferrer"> <img [alt]="pictureUrl" class="product-img"
      *ngIf="source" src={{source.pictureUrl}}> </a>
    <span  class="output-title-source">Codes promo & Bons plans <a href="{{source.affiliateUrl}}" target="_blank" rel="noopener noreferrer">{{source.name}}</a></span>
  </div>
</div>
<br>
<app-dashboard></app-dashboard>

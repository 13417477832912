import { Action } from '@ngrx/store';
import { SourceActions, SourceActionsTypesNgrx } from './sources.action';
import { Source } from './../../models/source';

export  const FeatureStateName = 'SourcesStateFromModule';

export enum SourcesStateEnum {
  LOADING= 'LOADING',
  LOADED= 'LOADED',
  ERROR= 'ERROR',
  INITIAL= 'INITIAL',
  NEW= 'NEW',
  EDIT= 'EDIT',
  UPDATED= 'UPDATED'
}

export interface SourcesState {

  sources: Array<Source>;
  selectSource: Source | null;
  errorMessage: string;
  dataStateNgrx: SourcesStateEnum;
  currentSource: Source|null;
  currentAction: SourceActions|null;
}

const initState: SourcesState = {
  sources: [],
  selectSource: null,
  errorMessage: '',
  dataStateNgrx: SourcesStateEnum.INITIAL,
  currentSource: null,
  currentAction:null

};

export function sourcesReducer (state: SourcesState= initState, action: Action): SourcesState {

    switch (action.type) {

    /*Get SOURCE*/
    case SourceActionsTypesNgrx.GET_ALL_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.GET_ALL_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, sources: (<SourceActions>action).payload, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.GET_ALL_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get SEARCH SOURCE*/
    case SourceActionsTypesNgrx.SEARCH_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING};
    case SourceActionsTypesNgrx.SEARCH_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, sources: (<SourceActions>action).payload, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.SEARCH_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get SOURCE BY NAME*/
    case SourceActionsTypesNgrx.GET_SOURCE_BY_NAME:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING};
    case SourceActionsTypesNgrx.GET_SOURCE_BY_NAME_SUCCESS:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, currentSource: (<SourceActions>action).payload, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.GET_SOURCE_BY_NAME_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get NEW SOURCE*/
    case SourceActionsTypesNgrx.NEW_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.NEW_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : SourcesStateEnum.NEW, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.NEW_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get SAVE SOURCE*/
    case SourceActionsTypesNgrx.SAVE_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.SAVE_SOURCE_SUCCESS:
      const prods: Source[] = [...state.sources];
      prods.push((<SourceActions>action).payload);
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, sources: prods, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.SAVE_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get EDIT SOURCE*/
    case SourceActionsTypesNgrx.EDIT_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.EDIT_SOURCE_SUCCESS:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, currentSource: (<SourceActions>action).payload, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.EDIT_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    /*Get UPDATE SOURCE*/
    case SourceActionsTypesNgrx.UPDATE_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.UPDATE_SOURCE_SUCCESS:
    const sourceUpdated: Source = (<SourceActions>action).payload;
    const sourceListUpdatedt = state.sources.map(p => (p._id === sourceUpdated._id) ? sourceUpdated : p);
    return {...state, dataStateNgrx : SourcesStateEnum.UPDATED, sources: sourceListUpdatedt, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.UPDATE_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};


    /*Get DELETE SOURCE*/
    case SourceActionsTypesNgrx.DELETE_SOURCE:
    return {...state, dataStateNgrx : SourcesStateEnum.LOADING, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.DELETE_SOURCE_SUCCESS:
    const sourceDelete: Source = (<SourceActions>action).payload;
    const index = state.sources.indexOf(sourceDelete);
    const list = [...state.sources];
    list.splice(index, 1);
    const dataSource: Source[] = list.map(p => p._id === sourceDelete._id ? sourceDelete : p);
    return {...state, dataStateNgrx : SourcesStateEnum.LOADED, sources: dataSource, currentAction: <SourceActions>action};
    case SourceActionsTypesNgrx.DELETE_SOURCE_ERROR:
    return {...state, dataStateNgrx : SourcesStateEnum.ERROR, errorMessage: (<SourceActions>action).payload, currentAction: <SourceActions>action};

    default : return {...state};
  }
}

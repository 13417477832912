<div class="container">
  <div>

    <h1>Mentions légales</h1>
    <br>
    <h2>Éditeur du site promodeep.com</h2>
    <br>
    <span>
      TechnoDeep France

    </span>
    <span>
      Micro Entreprise
      RCS Pontoise 951929306
    </span>
    <span>
      Code APE 7311Z

    </span>
    <span>
      Siège social : 28 Rue Danielle Casanova B 005, Argenteuil (95100) - FRANCE

    </span>
    <br>
    <span>
      Promodeep n'est pas un marchand en ligne mais un service de partage des offres de remises et codes promotionnels. Cela signifie que le
      site NE PERMET PAS aux utilisateurs de :
    </span>
    <span>

      - Commander, réserver ou réclamer des produits

    </span>
    <span>
      - S'informer du statut de leur commande, réservation ou contrat

    </span>
    <span>
      - S'informer des services proposés par les magasins ou les revendeurs

    </span>
    <span>
      Pour toutes ces informations, il est nécessaire de se référer directement à la boutique en ligne concernée.

    </span>
    <br>
    <br>
    <h3>Hébergeur</h3>
    <br>

    <span>
      OVH SASU Société par actions simplifiée à associé unique
    </span>
    <span>
      Lille Metropole B 424 761 419
    </span>
    <span>
      https://www.ovh.com
    </span>

    <br>
    <br>
    <h3>Conditions générales d'utilisation</h3>
    <br>
      L'utilisation du site www.promodeep.com implique l'acceptation pleine et entière des conditions générales
      d'utilisation décrites çi dessous.
    <br>
    <br>
    <h3>Politique de confidentialité et de protection de la vie privée</h3>
    <br>
    <!-- <span>
      Le Site www.promodeep.com est déclaré sous le numéro 1234567 auprès de la Commission Nationale Informatique et
      Libertés (CNIL).
    </span> -->
    <span>
      Les informations recueillies font l'objet d'un traitement informatique destiné à créer une base de données liée
      aux offres partagées en ligne sur notre palteforme.
    </span>
    <span>
      Nous vous invitons à consulter notre politique de confidentialité pour en savoir plus sur l'origine et l'usage des
      données de navigation traitées à l'occasion de votre consultation et utilisation du Site www.promodeep.com, et sur vos
      droits y afférent.
    </span>

    <br>
    <br>
    <h3>Droit d'auteur</h3>
    <br>
    <span>
      La marque promodeep est enregistrée auprès de l'INPI.
    </span>
    <span>
      Les marques citées sont la propriété de leurs propriétaires. aucune représentation, reproduction
      Toute traduction, modification ou citation, totale ou partielle, par quelque moyen que ce soit, est strictement interdite.
      Sans l'autorisation de TechnoDeep France, sauf dans les cas prévus à l'article L.112-5 du Code de la Propriété Intellectuelle.
      Image non contractuelle.
    </span>
  </div>
</div>

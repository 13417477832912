import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  throttleTime
} from 'rxjs/operators';
import { PublicHomeComponent } from 'src/app/componenets/public-home/public-home.component';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-nav-bar-footer-stiky',
  templateUrl: './nav-bar-footer-stiky.component.html',
  styleUrls: ['./nav-bar-footer-stiky.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('200ms ease-in'))
    ])
  ]
})
export class NavBarFooterStikyComponent implements OnInit {

  logo = 'assets/logo4.png';

  private isVisible = true;

  visibleSidebar4: boolean = false;

  //@ViewChild(PublicHomeComponent) publicHomeComponent!: PublicHomeComponent;
  @Input() publicHomeComponent: PublicHomeComponent = new PublicHomeComponent (this.deviceService);
  nativeElement: HTMLElement;
  mobile: boolean = false;

  constructor(myElement: ElementRef, public breakpointObserver: BreakpointObserver, private deviceService: DeviceDetectorService) {
    this.nativeElement = myElement.nativeElement;
   }

  ngOnInit(): void {

    this.breakpointObserver
    .observe(['(min-width: 390px)'])
    .subscribe((state: BreakpointState) => {

      if (state.matches) {
        this.mobile = false;

      } else {
        this.mobile = true;
      }
    });
  }



  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Hidden : VisibilityState.Visible;
  }

  ngAfterViewInit() {

    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const scrollDown = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    scrollUp$.subscribe(() => (this.isVisible = true));
    scrollDown.subscribe(() => (this.isVisible = false));
  }

  onScrollToFooter (){
    this.visibleSidebar4 = true;
  }

  onHideToFooter (){
    this.visibleSidebar4 = false;
  }

  onScrollToHeader (){
    this.publicHomeComponent.scrollToTop();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    this.visibleSidebar4 = false;
  }


}

<div class="container" style="  background-color: #292c2f;">
  <div class="footer">


    <div class="footer-distributed">
      <h2 class="branding"><img [src]="logo" width="40" height="40" [alt]="logo">Promo<span>Deep</span>
      </h2>
      <p class="footer-company-name">Lorsque vous cliquez sur un lien, nous sommes susceptibles de recevoir une
        commission.</p>
      <mat-divider></mat-divider>
      <br>
      <div class="footer-left">




        <p style="color: white; font-weight: bold;">Entreprise</p>
        <div>
          <a class="a-padding" routerLink="contact">Contact</a>
        </div>
        <div>
          <a class="a-padding" routerLink="about">A propos nous</a>
        </div>



      </div>

      <div class="footer-center">


        <p style="color: white; font-weight: bold;">Légal</p>
        <div>
          <a class="a-padding" routerLink="legalNotice">Mentions légales</a>
        </div>
        <div>
          <a class="a-padding" routerLink="privacyPolicy">Politique de confidentialité</a>
        </div>
        <div>
          <a class="a-padding" routerLink="cookies">Politique d'utilisation des cookies</a>
        </div>
        <div>
          <a class="a-padding" routerLink="generalPolicy">Conditions générales d'utilisations</a>
        </div>
      </div>
        <div class="footer-right">

          <p class="footer-company-about">
            <span>Rejoignez nous</span>
          </p>

          <div class="footer-icons">

            <a href="https://www.facebook.com/PromodeepCom" target="_blank" rel="noopener noreferrer" aria-label="facebook">
              <p class="pi pi-facebook"></p>
            </a>
            <a href="https://www.instagram.com/promodeepcom" target="_blank" rel="noopener noreferrer" aria-label="instagram">
              <p class="pi pi-instagram"></p>
            </a>
            <a href="https://twitter.com/PromodeepCom" target="_blank" rel="noopener noreferrer" aria-label="twitter">
              <p class="pi pi-twitter"></p>
            </a>
            <a href="https://www.youtube.com/channel/UCCa1NbA_FNZxT14zPUwbmGg" target="_blank" rel="noopener noreferrer" aria-label="youtube">
              <p class="pi pi-youtube"></p>
            </a>
            <!-- <a href="https://www.instagram.com/promodeepcom" target="_blank" rel="noopener noreferrer" aria-label="instagram">
              <p class="pi pi-whatsapp"></p>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="linkedin">
              <p class="pi pi-linkedin"></p>
            </a> -->
          </div>
          <br>
          <p class="footer-company-about">
            <span>Trouver nos applications sur les stores</span>
          </p>

          <div class="footer-icons">

            <a href="https://apps.apple.com/app/promodeep/id6448401465" target="_blank" rel="noopener noreferrer" aria-label="IOS">
              <p class="pi pi-apple"></p>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.promodeep.twa" target="_blank" rel="noopener noreferrer" aria-label="android">
              <p class="pi pi-android"></p>
            </a>
          </div>

        </div>

      </div>
      <br>
      <div class="footer-distributed">
        <mat-divider></mat-divider>

        <p class="footer-company-name"> <a href="https://www.technodeep.tech/" target="_blank" rel="noopener noreferrer" aria-label="technodeep">
          TechnoDeep
        </a> &copy; <span class="year">{{year}}</span> - <b>PromoDeep</b>, tous droits réservés.</p>
      </div>
      <br>
      <br>
      <br>
    </div>

  </div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'src/app/services/cookie.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analaytics.service';
import { SharedService } from 'src/app/services/shared.service';
import { DialogContentExampleDialog } from './dialog-content/dialog-content-example-dialog';
import { environment } from 'src/environments/environment';
import { Platform } from '@angular/cdk/platform';


declare let gtag: Function;

export interface DialogData {
  acceptAnalytics: boolean;
}

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
  providers: [GoogleAnalyticsService]
})
export class CookieDialogComponent implements OnInit {


  public closePopup = false;
  acceptAnalytics: boolean = true;
  isIOS: boolean = false;
  isAndroid: boolean = false;

  constructor(private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    public sharedService: SharedService,
    public cookie: CookieService,
    private platform: Platform,
    public dialog: MatDialog) { }




  ngOnInit() {
    this.isIOS = this.platform.IOS;
    this.isAndroid = this.platform.ANDROID;
    if (this.getConsent()) {

      this.closePopup = true;
    }
    else {

       if(this.isIOS) {
         this.acceptAnalytics = false;
         this.closePopup = true;
      //   //this.accept();
       }
      //delete all cookies from this page

      this.sharedService.acceptedConsent = false;

    }
  }

  public showCookieDialog() {
    this.closePopup = false;
  }


  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-B0XK465P72";
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  public deny(): void {
    this.deleteCookie("_ga");
    this.deleteCookie("_ga_B0XK465P72");
    this.setConsent(true);
    this.closePopup = true;
  }

  public accept(): void {
    let cookies_consented = localStorage.getItem('cookies_consented');
    if (this.acceptAnalytics) {
      this.addGAScript();
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (typeof gtag !== 'undefined') {
            gtag('config', 'G-B0XK465P72', {
              page_path: event.urlAfterRedirects,
            });
          }
        }

      });
      this.loadScript();
      this.googleAnalyticsService.eventEmitter('TEST', 'User visited page', "User visited page", 'accept dialog', 1);
      this.sharedService.acceptedConsent = true;
    }


    this.setConsent(true);

    this.closePopup = true;
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
}

  public getConsent(): boolean {

    return localStorage.getItem('cookies_consented') === 'true';

  }

  public setConsent(value: boolean): void {
    localStorage.setItem('cookies_consented', value ? 'true' : 'false');
  }



  // private getCookie(name: string) {
  //   let ca: Array<string> = document.cookie.split(';');
  //   let caLen: number = ca.length;
  //   let cookieName = `${name}=`;
  //   let c: string;

  //   for (let i: number = 0; i < caLen; i += 1) {
  //     c = ca[i].replace(/^\s+/g, '');
  //     if (c.indexOf(cookieName) == 0) {
  //       return c.substring(cookieName.length, c.length);
  //     }
  //   }
  //   return '';
  // }

  private deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }


  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      data: { acceptAnalytics: this.acceptAnalytics },
      width: '500px',
    });
    this.closePopup = true;
    dialogRef.afterClosed().subscribe(result => {
      this.closePopup = false;
      this.acceptAnalytics = result;
    });
  }

}


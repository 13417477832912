import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { Meta, Title } from '@angular/platform-browser';
import { pathRoutingEnum } from 'src/assets/constantEnum';
import { environment } from 'src/environments/environment';
import { ProductService } from 'src/app/services/product.service';
import { EditProductAction, UpdateProductAction } from 'src/app/ngrx/products.action';
import { ProductsState } from 'src/app/ngrx/products.reducer';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { getItemById, selectItems } from 'src/app/ngrx/products.selector';
import { TimeCalculator } from 'src/app/shared/time-calcultor';
import { CanonicalService } from 'src/app/services/canonical.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-product-details-ngrx',
  templateUrl: './product-details-ngrx.component.html',
  styleUrls: ['./product-details-ngrx.component.scss'],
})
export class ProductDetailsNgrxComponent implements OnInit {
  host = environment.host;
  productId!: string;
  product!: Product;
  name = 'ngx sharebuttons';
  public hrefProduct: string = "";
  public hrefDetailProduct = "";
  copieCodePromotion = false;
  timeCalculator = new TimeCalculator;
  public linkToShare!: string;
  public shareTitle!: string;
  public shareDescription!: string;
  public shareImage!: string;
  @Input() state: ProductsState | null = null;
  viewNumber!: number;
  dealBloced: boolean = false;
  diffEndDate!: string;
  isIOS: boolean = false;

  constructor(private store: Store<any>, private activatedRoute: ActivatedRoute, private router: Router, private productService: ProductService, private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private platform: Platform,
    private _location: Location) {
    //this.state = store.select(this.state!.products);
    this.productId = activatedRoute.snapshot.params.id;
  }

  /*injectMetadatas(product: Product)
  {

    let imageProduct = product.pictureUrl ? this.urlHelper.toAbsoluteUrl(product.pictureUrl) : this.urlHelper.toAbsoluteUrl(product.pictureUrl);
    let urlPage = QUIZ_CONFIG.shareBaseUrl + "/" + product._id;
    this.meta.updateTag({ name: 'title', content: Globals.metadatasConfig.titleRoot + ' - ' + product.name});
    this.meta.updateTag({ name: 'description', content: Globals.metadatasConfig.descriptionRoot + ' - ' + product.description});
    this.meta.updateTag({ name: 'keywords', content:Globals.metadatasConfig.keywordsRoot });
    this.meta.updateTag({ property: 'og:site_name', content: Globals.metadatasConfig.siteName + ' - ' + product.name});
    this.meta.updateTag({ property: 'og:url', content: urlPage});
    this.meta.updateTag({ property: 'og:title', content: Globals.metadatasConfig.titleRoot + ' - ' + product.name});
    this.meta.updateTag({ property: 'og:description', content: Globals.metadatasConfig.descriptionRoot + ' - ' + product.description});
    this.meta.updateTag({ property: 'og:image', content: product.pictureUrl});
    this.meta.updateTag({ name: 'twitter:card', content: "summary"});
    this.meta.updateTag({ name: 'twitter:site', content: Globals.metadatasConfig.twitterSite});
    this.meta.updateTag({ name: 'twitter:url', content: urlPage});
    this.meta.updateTag({ name: 'twitter:image', content: product.pictureUrl});
    this.meta.updateTag({ name: 'twitter:account_id', content: Globals.metadatasConfig.twitterAccountId});
    this.meta.updateTag({ name: 'google-site-verification', content: Globals.metadatasConfig.googleSiteVerification});

  }*/


  ngOnInit(): void {

    this.canonicalService.setCanonicalURL();
    this.isIOS = this.platform.IOS;
    console.log(this.productId);

    this.store.select(getItemById(this.productId.toString())).pipe(
      tap()).subscribe((item) => {
        this.product = item!;
      });
    if (!this.product) {
      this.store.dispatch(new EditProductAction(this.productId));
      this.store.subscribe((myStore) => {
        this.state = myStore.ProductsStateFromModule;
        this.product = this.state!.currentProduct!;
      });
    }
    if (!this.product) {
      this.productService.getSearchAmazonProducts(this.productId).subscribe(data => {
        this.product = data[0];
      });
    }


    if (this.product != null) {
      if (this.router.url.includes("product")) {
        this.title.setTitle(this.product.name + " - promodeep.com");
        this.metaTagService.updateTag(
          { name: "description", content: "offre promotionnelle de " + this.product.percentageDiscount + "% pour " + this.product.name },
        );
      }
      // this.linkToShare = this.host + this.router.url;
      // this.shareTitle = this.product.name;
      // this.shareDescription = this.product.description;
      // this.shareImage = this.product.pictureUrl;
      //this.injectMetadatas(this.product);


      // this.meta.addTag({ property: 'og:site_name', content: this.product.name });
      // this.meta.addTag({ property: 'og:url', content: this.host + "/product/62087528f5764f913dd1b651" });
      // this.meta.addTag({ property: 'og:title', content: this.product.name });
      // this.meta.addTag({ property: 'og:description', content: this.product.description });
      // //this.meta.addTag({ property: 'og:image', content: this.product.pictureUrl});
      // this.meta.addTag({ property: 'og:image', content: this.product.pictureUrl, itemprop: 'image' });
      // this.meta.addTag({ property: 'og:image:url', content: this.product.pictureUrl, itemprop: 'image' });
      // this.meta.addTag({ property: 'og:image:type', content: 'image/jpg' });
      // this.meta.addTag({ property: 'og:image:width', content: '300' });
      // this.meta.addTag({ property: 'og:image:height', content: '200' });

      // this.meta.addTag({ name: 'twitter:card', content: "summary" });
      // this.meta.addTag({ name: 'twitter:url', content: this.host + "/product/62087528f5764f913dd1b651" });
      // this.meta.addTag({ name: 'twitter:image', content: this.product.pictureUrl });
      // this.meta.addTag({ property: 'twitter:image:width', content: '300' });
      // this.meta.addTag({ property: 'twitter:image:height', content: '200' });
      //this.meta.updateTag({ name: 'twitter:account_id', content: Globals.metadatasConfig.twitterAccountId});
      //this.meta.updateTag({ name: 'google-site-verification', content: Globals.metadatasConfig.googleSiteVerification});






    }
    this.hrefProduct = this.router.url;
    this.linkToShare = this.router.url;


    //this.store.select(selectItems(this.state));
    /*this.store.select(getItemById(this.productId)).pipe(
      tap(res =>
      )calculateDiffEndDate
    )
    .subscribe((item) => {
      this.product = item!;
    });*/

  }

  calculateDiffAddDate(dateSent: Date) {
    var calculateDiffEndDate = "";
    if (dateSent) {
      let currentDate = new Date();
      dateSent = new Date(dateSent);
      var diffMs = currentDate.getTime() - dateSent.getTime();
      var diffDays = Math.floor(diffMs / 86400000); // days
      if (diffDays > 1) {
        //calculateDiffEndDate = calculateDiffEndDate + diffDays + " j, "
        calculateDiffEndDate = " " + dateSent.getDate() + " " + this.monthNames[dateSent.getMonth()]
        if (dateSent.getFullYear() < currentDate.getFullYear()) {
          calculateDiffEndDate = calculateDiffEndDate + " " + dateSent.getFullYear();
        }
      } else {
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        //calculateDiffEndDate = calculateDiffEndDate + diffDays + " j, "
        if (diffHrs > 0) {
          calculateDiffEndDate = calculateDiffEndDate + diffHrs + " h "
        }
        if (diffMins >= 0) {
          calculateDiffEndDate = calculateDiffEndDate + diffMins + " m"
        }
        if (calculateDiffEndDate) {
          calculateDiffEndDate = "  " + calculateDiffEndDate;
        }
      }
    }
    return calculateDiffEndDate;
  }

  monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juiller", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
  ];

  calculateDiffEndDate(product: Product) {
    this.dealBloced = false;
    this.diffEndDate = this.timeCalculator.calculateDiffEndDate(product);
    if (this.diffEndDate.includes("Expiré")) {
      this.dealBloced = true;
    }
    return this.diffEndDate;
  }

  onFilterBySource(productSource: any) {
    this.redirectTo(pathRoutingEnum.source.valueOf() + '/' + productSource);
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  copyInputMessage(inputElement: any) {
    inputElement.select(); document.execCommand('copy'); inputElement.setSelectionRange(0, 0);

  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copieCodePromotion = true;
  }

  incrementViewNumberProduct(product: Product) {

    this.viewNumber = product.viewNumber;
    this.product = { ...product, viewNumber: product.viewNumber + 1 }
    this.store.dispatch(new UpdateProductAction(this.product));
    // this.cookie.setCookie({
    //   productUrl: "productUrl",
    //   value: product.affiliateUrl,
    //   session: true,
    // });
  }

  async sharePage() {

    const baseurl = this.router.url;
    const title = this.product.name;
    //const blob = await fetch(this.product.pictureUrl).then(r => r.blob());

    if (navigator.share) {
      if (navigator.canShare && navigator.canShare()) {
        navigator.share({
          // files: [
          //   new File([blob], 'file.png', {
          //     type: blob.type,
          //   }),
          // ],
          text: title,
          title: title,
          url: baseurl
        });
      } else {
        navigator.share({
          // files: [
          //   new File([blob], 'file.png', {
          //     type: blob.type,
          //   }),
          // ],
          text: title,
          title: title,
          url: baseurl
        });
      }
    } else {
      document.location.href = "whatsapp://send?text=" + "baseurl";
    }
  }

  backClicked() {
    this._location.back();
  }

}


import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle("Politique de confidentialité et de protection de la vie privée - promodeep.com");
    this.metaTagService.updateTag (
      { name:"description", content:"➤ Nous vous invitons à consulter notre politique de confidentialité pour en savoir plus sur l'origine et l'usage des données de navigation traitées - promodeep.com"},
    );
  }

}

<div class="container">
  <div>
    <div *ngIf="product">
      <span hidden
        *ngIf="product.expirationDate"><mat-icon>hourglass_empty</mat-icon>{{calculateDiffEndDate(product)}}</span>
      <div class="product-list-item" *ngIf="product.available">
        <button *ngIf="isIOS" mat-mini-fab type="button" class="mat-elevation-z0 button-back" (click)="backClicked()">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <div class="demoWrapper">
          <div class="container-img">
            <a href="{{product.affiliateUrl}}" target="_blank" rel="noopener noreferrer"><img [alt]="product.pictureUrl"
                [ngClass]="[dealBloced ===true ? 'product-img-expired' : 'product-img', product.selected ===false ? 'product-img-expired' : 'product-img'] "
                src={{product.pictureUrl}}></a>


            <div>
              <span *ngIf="dealBloced ===true || product.selected ===false"  style="background-color: red;">Expiré</span>
            </div>


          </div>
          <!-- <div *ngIf="product.partner === 'Amazon'">
            <div [innerHTML]="product.pictureUrl" style="width: 100%;"></div>
          </div> -->








          <button type="button" class="btn btn mat-button-toggle-appearance-standard position-relative share-button"
            (click)="sharePage()" id="shareFilesButton">
            Partager
            <mat-icon>share</mat-icon>
          </button>
        </div>

        <div class="product-list-detail">
          <div class="toast-header-custom">


            <a routerLink="/"
              [ngClass]="[dealBloced ===true ? 'see-source-expired' : 'see-source', product.selected ===false ? 'see-source-expired' : 'see-source' ]"
              (click)="onFilterBySource(product.source)">{{product.source}}</a>
            <strong class="me-auto"></strong>

            <span *ngIf="product.percentageDiscount"
              [ngClass]="[dealBloced ===true ? 'percentageDiscount-expired' : 'percentageDiscount', product.selected ===false ? 'percentageDiscount-expired' : 'percentageDiscount' ]">-{{product.percentageDiscount}}%</span>
          </div>
          <div class="toast-body">
            <div>

              <p
                [ngClass]="[dealBloced ===true ? 'product-name-expired' : 'product-name', product.selected ===false ? 'product-name-expired' : 'product-name' ]">
                {{product.name}}</p>
            </div>
            <span *ngIf="product.priceDiscount"
              [ngClass]="[dealBloced ===true ? 'product-price-expired' : 'product-price', product.selected ===false ? 'product-price-expired' : 'product-price' ]">{{product.priceDiscount}}€</span>
            <span *ngIf="product.price"
              [ngClass]="[dealBloced ===true ? 'oldPrice-expired' : 'oldPrice', product.selected ===false ? 'oldPrice-expired' : 'oldPrice' ]">{{product.price}}€</span>

            <span *ngIf="product.deliveryPrice && product.deliveryPrice>0"
              [ngClass]="[dealBloced ===true ? 'deliveryPrice-expired' : 'deliveryPrice', product.selected ===false ? 'deliveryPrice-expired' : 'deliveryPrice' ]"><mat-icon>
                local_shipping</mat-icon>{{product.deliveryPrice}}€</span>

            <span *ngIf="product.deliveryPrice==0"
              [ngClass]="[dealBloced ===true ? 'deliveryPrice-expired' : 'deliveryPrice', product.selected ===false ? 'deliveryPrice-expired' : 'deliveryPrice' ]"><mat-icon>
                local_shipping</mat-icon>Gratuit</span>

            <a *ngIf="product.area" routerLink="/"
              [ngClass]="[dealBloced ===true ? 'see-area-expired' : 'see-area', product.selected ===false ? 'see-area-expired' : 'see-area' ]"><mat-icon>
                language</mat-icon>{{product.area}}</a>
            <div class="product-list-action">
              <br>
              <button type="button" mat-button color="primary"
                *ngIf="product.promotionCode && copieCodePromotion===false"
                class="btn btn position-relative promotion-code" (click)="copyMessage(product.promotionCode)">
                {{product.promotionCode}}
                <mat-icon>content_copy</mat-icon>
              </button>
              <button type="button" mat-button color="primary" *ngIf="product.promotionCode && copieCodePromotion"
                class="btn btn position-relative promotion-code" (click)="copyMessage(product.promotionCode)">
                {{product.promotionCode}}
                <span class="badge rounded-pill bg-light text-dark">copié
                </span>
              </button>
              <!-- <a pButton class="href" (click)="incrementViewNumberProduct(product)" class="affiliateUrlBtn"
                href="{{product.affiliateUrl}}" icon="pi pi-shopping-cart" label="Voir le deal" target="_blank"></a> -->

              <a type="button" class="btn btn position-relative" href="{{product.affiliateUrl}}" target="_blank"
                [ngClass]="[dealBloced ===true ? 'mat-stroked-button-expired' : 'mat-stroked-button', product.selected ===false ? 'mat-stroked-button-expired' : 'mat-stroked-button' ]"
                (click)="incrementViewNumberProduct(product)">
                <!-- <mat-icon matBadge={{product.viewNumber}} matBadgeColor="warn"
                  *ngIf="product.viewNumber >= 0 && product.viewNumber <= 999"
                  [ngStyle]="{'color':'white', 'vertical-align': 'middle'}">visibility</mat-icon>
                <mat-icon matBadge="999" matBadgeColor="warn" *ngIf="product.viewNumber > 999"
                  [ngStyle]="{'color':'white'}">visibility</mat-icon> -->
                <span class="button-text">Voir l'offre</span>
              </a>



            </div>
            <br>
            <div title="description"
              [ngClass]="[dealBloced ===true ? 'description-expired ' : 'description', product.selected ===false ? 'description-expired ' : 'description' ]"
              [innerHTML]="product.description"></div>


          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</div>

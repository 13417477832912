import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandProduct } from '../models/brand-product';

@Injectable({
  providedIn: 'root'
})
export class BrandProductService {

  constructor(private httpClient: HttpClient) { }

  searchBrandProduct(searchKeyWords: string): Observable<BrandProduct[]> {
    const host = environment.host;
      /*return this.httpClient.get<Product[]>(host + '/products?name_like=' + searchKeyWords);*/
      return this.httpClient.get<BrandProduct[]>(host + '/brandProducts/searchBrandProducts/' + searchKeyWords);

  }
}
